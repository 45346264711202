@import '../../styles/utils';
@import '../../styles/variables';
@import '../../styles/mixins';

.upload-asset-library {
  .Checkboxes {
    opacity: 0;
  }

  &__none-selected {
    @include style-subtitle;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 15rem;
    width: 100%;
  }

  &__assets {
    display: flex;
    flex-wrap: wrap;
    padding: 0.65rem 0;
    width: 102%;
    margin-left: -0.438rem;
    z-index: 0;
  }

  &__asset {
    position: relative;
    width: 25%;
    padding: 1rem 0.5rem;
    &:hover {
      .Checkboxes {
        opacity: 1 !important;
      }
    }
  }

  &__asset-active {
    .Checkboxes {
      opacity: 1;
    }
  }

  &__selected {
    position: absolute;
    z-index: 9;
    margin-left: 0.8rem;

    .control--checkbox {
      margin-top: 0.8rem;
    }
  }

  &__asset-name {
    @include style-body-small;
    color: $gray;
  }

  &__filters {
    display: flex;
    padding-top: 1rem;
  }

  &__type {
    img {
      width: 20px;
      height: 20px;
    }
  }

  &__dropdown {
    border-radius: 1px;
    padding: 0.5rem 0.8rem;
    position: relative;
    color: $gray-medium;
    @include style-subtitle;
    font-size: 0.625rem;
    cursor: pointer;
    display: flex;
    margin-right: 0.25rem;
    border: 1px solid transparent;

    @media (max-width: $tablet-breakpoint) {
      position: unset;
    }

    img {
      padding-left: 0.5rem;
    }
  }

  &__popup {
    position: absolute;
    box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.12);
    border-radius: 2px;
    z-index: 100;
    background-color: $neutral-white;
    min-width: 8rem;
    left: 0;
    right: unset;
    top: 2.5rem;
    padding: 1rem 1rem;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
      min-width: 100%;
      left: 0;
      right: 0;
      margin: 0px auto;
    }

    .Checkboxes {
      opacity: 1;
    }
  }

  &__option {
    @include style-body-small;
    text-transform: none;
    letter-spacing: 0;
    padding-bottom: 0.8rem;

    &:last-child {
      padding-bottom: 0;
    }

    &:hover {
      cursor: pointer;
      color: $black-2;
    }
  }

  .tile {
    cursor: pointer;
    margin-bottom: 0.25rem;
  }

  .modal__header {
    border-bottom: 0.063rem solid $gray-light;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .modal__footer {
    border-top: 0.063rem solid $gray-light;
    border-radius: 0 0 0.5rem 0.5rem;
  }

  .modal__content {
    @include center(both);
    width: 808px;
    min-height: 25rem;
    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }
  }

  &__folder {
    width: 100%;
    background-color: $white;
    cursor: pointer;
    border-bottom: 1px solid $gray-light;

    @include style-subtitle;
    background-color: $white;
    padding: 1.2rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__folder-icon {
      margin-right: 1.5rem;
    }

    &__heading-flex {
      display: flex;
      justify-content: baseline;
    }
  }
}

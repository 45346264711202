@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.search {
  position: relative;
  overflow: hidden;

  &::placeholder {
    @include style-body-regular;
    color: $gray-medium;
  }

  &__search-icon {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;

    &:focus,
    &:active {
      outline: none;
      border-color: $gray;
    }
  }

  &__empty {
    .search__search-close {
      right: -1.875rem;
    }
  }

  &__typing {
    .search__search-icon {
      right: -1.875rem;
    }
  }

  &__search-close {
    position: absolute;
    right: 0.4rem;
    top: 1px;
    cursor: pointer;

    &:focus,
    &:active {
      outline: none;
      border-color: $gray;
    }
  }

  &__input {
    @include style-body-regular;
    border-radius: 3px;
    border: 1px solid $gray-lighter;
    padding: 0.2rem 2rem 0.2rem 0.5rem;
    color: $text-color;
    position: relative;
    width: 100%;

    &:focus,
    &:active {
      outline: none;
      border: 1px solid $gray;
    }
  }
}

.mm {
  &--h1 {
    @include style-title-large;
  }

  &--h2 {
    @include style-title-regular;
  }

  &--h3 {
    @include style-title-medium;
    margin: 0;
  }

  &--h4 {
    @include style-body-x-large;
    margin: 0;
  }

  &--h5 {
    @include style-body-large;
    margin: 0;
  }

  &--h6 {
    @include style-body-regular;
    margin: 0;
  }

  &--p {
    @include style-body-small;
  }
}

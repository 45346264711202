@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.frame {
  background: $neutral-black;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;

  &__body {
    overflow-y: auto;
    height: calc(100% - 4rem);
    scroll-behavior: smooth;
  }

  &__inner-v2 {
    min-height: 100vh;
    padding-top: $frame-top-header;
  }

  &__logo-wrap {
    position: relative;
    display: flex;
    max-width: 10vw;
  }

  &__inner {
    background-color: $neutral-white;
    height: 100%;
    overflow-x: hidden;
    padding: 2rem 0;
    min-height: 100vh;
    padding-top: $frame-top-header;

    @media (max-width: $tablet-breakpoint) {
      padding: 0;
    }
  }

  &__back-svg {
    position: relative;
    left: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 0.8;
  }

  &__page-title {
    @include style-title-xsmall;
    color: $white;
    text-transform: uppercase;
    opacity: 0.6;

    @media (max-width: $tablet-breakpoint) {
      display: none;
    }
  }

  &__body {
    position: relative;
    overflow-x: hidden;
    height: 100vh;
    scroll-behavior: smooth;
    background-color: $frame-bg-gray;
    main {
      // margin-top: 1.5rem;
    }
  }

  &__img {
    max-height: 3.375rem;
  }

  &__chat {
    cursor: pointer;
    position: absolute;
    right: -0.1rem;
    top: 25rem;
    transform: rotate(90deg);
    color: $white;
    letter-spacing: 0.375rem;
    font-size: 0.625rem;
    display: flex;
    align-items: center;
  }

  &__chat-notifications {
    position: relative;
  }

  &__chat-msg-num {
    @include style-title-xsmall;
    border-radius: 100%;
    background-color: $color-red;
    color: $white;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    position: absolute;
    left: 0.2rem;
    bottom: -0.5rem;
  }

  &__slash {
    cursor: pointer;
    position: absolute;
    left: 0.6rem;
    top: 25rem;
  }

  &__close {
    &::before {
      position: absolute;
      top: 406px;
      left: 25px;
      content: '';
      width: 2px;
      height: 23px;
      background-color: $white;
      transform: rotate(45deg);
      transition: 350ms ease transform;
    }
  }

  &__close-active {
    &::before {
      position: absolute;
      top: 406px;
      left: 25px;
      content: '';
      width: 2px;
      height: 23px;
      background-color: $white;
      transform: rotate(-45deg);
      transition: 350ms ease transform;
    }
  }

  &__nav {
    position: relative;
    padding: 0.58rem 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 3.385rem;
    position: absolute;
    width: 100%;
    background-color: $black;
    top: 0;
    left: 0;
    right: 0;
    height: $frame-top-header;
    z-index: 99;
  }

  &__notification {
    position: relative;
    height: 0;
    width: 0;
  }

  &__search {
    cursor: pointer;
  }

  &__bell {
    position: relative;
    cursor: pointer;
  }

  &__bell-wrap {
    position: relative;
  }

  &__black {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
  }

  &__container {
    padding: 0 3.125rem;
    width: 100%;
    height: 100%;
    @media (max-width: $tablet-breakpoint) {
      padding: 1rem 1rem;
    }
    &__headerOnly {
      padding: 1.163rem 0 0 0;
    }
  }

  &__bell-dot-active {
    position: absolute;
    background-color: $notification-bell-color;
    width: 0.375rem;
    height: 0.375rem;
    right: 0;
    border-radius: 100%;
    top: 0;
    z-index: 9;
    border: 1px solid $black-2;
  }

  &__logged-in {
    position: relative;
    margin-right: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 7.5rem;
    justify-content: space-between;
  }

  .pop-up__expand {
    top: 2.5rem;
    z-index: 98;
  }

  &__sidebar {
    position: fixed;
    background-color: $white;
    width: 15rem;
    border: 1px solid $gray-light-3;
    z-index: 100;
    top: 4.6rem;
    left: 3.1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 4.6rem);
  }

  &__sb-header {
    text-align: center;
    padding: 2rem;
  }

  &__sb-footer {
    padding: 2rem;
    border-top: 1px solid $gray-light-3;
    .frame__sb-item {
      font-size: 0.625rem;
      color: $gray-light-2;
    }
  }

  &__sb-item {
    @include style-title-small;
    cursor: pointer;
    text-transform: uppercase;
    color: $gray;
    font-size: 0.75rem;
    padding-bottom: 1.5rem;
    transition: 0.35s ease color;

    &:hover {
      color: $black-2;
      transition: 0.35s ease color;
    }
  }

  .header__back-btn {
    @include style-title-xsmall;
    color: $gray;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .frame__back-btn {
    cursor: pointer;
  }
}

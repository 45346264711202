@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.dashboard-nav {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;

  @media (max-width: $tablet-breakpoint) {
    flex-direction: column;
  }

  &__btn {
    @include style-body-xsmall;
    font-family: $nunito-bold;
    color: $gray;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 0 2rem;
    cursor: pointer;

    @media (max-width: $tablet-breakpoint) {
      padding: 0.7rem 1rem;
    }

    &.active {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        bottom: -16px;
      }
    }
  }

  //Modifiers
  &--dark-mode {
    background-color: $dashboard-nav-bg;

    .dashboard-nav__btn.active {
      color: $neutral-white;

      &:before {
        background-color: $neutral-white;
      }
    }
  }

  &--light-mode {
    background-color: $neutral-white;
    border-bottom: 1px solid $gray-light-3;

    .dashboard-nav__btn.active {
      color: $text-color;

      &:before {
        background-color: $text-color;
      }
    }
  }
}

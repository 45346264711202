@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.materials {
  &__note {
    @include style-body-regular;
    color: $gray;
    padding-bottom: 1rem;
  }

  &__item {
    border-bottom: 1px solid $gray-light-3;
    padding: 1.5rem 0;

    &:first-child {
      padding-top: 0;
    }
  }

  &__card {
    width: 180px;
    height: 180px;
    background-position: center;
    background-size: cover;
    margin-top: 1rem;
    @media (max-width: $tablet-breakpoint) {
      height: 100px;
      width: 100px;
    }
  }

  &__front-back {
    display: flex;
  }

  &__item-material {
    margin-right: 2rem;
    text-align: center;
    @include style-body-xsmall;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__item-wrap {
    display: flex;
    @media (max-width: $tablet-breakpoint) {
      display: flex;
      flex-direction: column;
    }
  }

  &__info {
    padding-top: 2.2rem;
  }

  &__info-line {
    @include style-body-xsmall;
    width: 180px;
    text-transform: uppercase;
    padding-bottom: 1rem;
    color: $gray-medium;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.1em;

    span {
      width: auto;
      text-transform: capitalize;
      letter-spacing: 0;
      font-weight: normal;
    }
  }

  &__edit {
    padding-top: 2.2rem;
  }
}

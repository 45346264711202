@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.additional-reference {
  position: relative;
  background-color: $neutral-white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  max-width: 62.6rem;

  @media (max-width: $tablet-breakpoint) {
    max-width: 100%;
  }

  .product-upload {
    padding: 16px 44px;
    &__drag-area {
      margin-top: 0;
      height: 455px;
    }
  }

  &__heading {
    @include style-title-medium;
    font-family: $optima;
    letter-spacing: 0;
    border-bottom: solid 1px $gray-light-3;
  }

  &__heading-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 0;
    margin: 0 40px;
  }

  &__folder-path {
    margin: 0 40px;
  }

  &__heading-icons {
    display: flex;
    align-items: center;

    & .tooltip-block {
      margin-left: 32px;
    }

    & .tooltip-block--svg {
      margin-right: 0;
    }

    & .tooltip {
      width: 10rem;
    }
  }

  &__body {
    padding: 1rem 1.5rem;
    min-height: 520px;

    & .inventory-table {
      max-height: 425px;
      overflow-x: hidden;
    }

    & .image-gallery-thumbnails-wrapper {
      & .image-gallery-thumbnails {
        overflow-y: unset;
        overflow-x: hidden;
        height: 455px !important;
        width: 100px;
        & .image-gallery-thumbnails-container {
          flex-direction: column;
          & .image-gallery-thumbnail {
            width: 100px;
            height: 100px;
            z-index: 0;
          }
        }
        -ms-overflow-style: none; /* IE and Edge - Hide scrollbar */
        scrollbar-width: none; /* Firefox - Hide scrollbar */
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    & .image-gallery-slide-wrapper {
      position: absolute;
      &.left {
        display: inline-block;
        width: calc(100% - 90px); // 110px total (90px + 20px for margin)
        padding: 0 40px 0 20px;
      }
      & .image-gallery-left-nav {
        padding-left: 30px;
      }
    }
  }

  &__thumbnail {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
  }

  &__thumbnail-img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    margin-right: 0;
  }

  &__actions {
    padding: 1rem 2rem;
  }

  &__tooltip {
    padding: 0rem 2rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__history {
    cursor: pointer;
  }

  &__slide {
    position: relative;
    &.swiper-slide {
      background-position: center;
      background-size: cover;
      width: 770px;
      height: 495px;
    }
  }

  &__viewmode {
    cursor: pointer;
    margin-left: 32px;
  }

  &__add {
    cursor: pointer;
    margin-left: 24px;
  }

  .atelierModelUploads__gallery-thumb {
    position: relative;
  }

  .thumbnail-header {
    position: absolute;
    background-color: transparent;
    height: 32px;
    width: 100%;
    right: 0.05rem;
    top: 0.05rem;
    z-index: 100;
    display: flex;
    justify-content: flex-end;

    img {
      filter: invert(50%);
    }
    .threedots {
      height: 32px;
      width: 32px;
      border-radius: 4px;
      margin: 8px 8px 0 0;

      & .pop-up__expand {
        min-width: unset;
      }
    }
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/common/buttons';

.product-slider {
  padding: 0 0rem;
  border: 0.063rem solid $gray-light-3;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  &__slider {
    position: relative;
    padding: 0 2.5rem;
  }

  &__slide-tile {
    margin-right: 0.5rem !important;
    color: $gray;
  }

  &__slide-plus-txt {
    @include style-body-xsmall;
    text-align: center;
    color: $gray-light-2;
  }

  &__img-position {
    background-color: $white;
    @include style-body-xsmall;
    color: $light-black;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.5rem;
    left: 0.5rem;
    bottom: 0.5rem;
  }

  &__arrows {
    position: absolute;
  }

  &__arrow-next {
    right: 1rem;
    top: 45%;
  }

  &__arrow-prev {
    left: 1rem;
    top: 45%;
  }

  &__slide-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__p {
    @include style-body-xsmall;
    color: $gray-medium;
    padding-top: 0.4rem;
    padding-bottom: 1rem;
  }

  &__title {
    @include style-heading-small;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $black-2;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2.5rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__title-maxwidth {
    a {
      max-width: 80%;
    }
  }

  &__addition {
    @include button-badge;
    font-size: 0.425rem;
    margin-right: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: #e2e8f0;
    height: max-content;
  }

  &__img {
    cursor: pointer;
    &-position {
      position: absolute;
    }
  }

  &__detail {
    @include style-heading-small;
    color: $gray-dark;
    text-transform: uppercase;
    font-family: $nunito-bold;
    padding-bottom: 0.5rem;
    span {
      color: $gray;
    }
  }

  &__bottom {
    padding-top: 1rem;
    padding-left: 2.5rem;
  }

  &__slide-plus {
    height: 100% !important;
    border: 0.063rem dashed $gray-light;
    background: $drag-bg;
    height: 9.375rem !important;
    width: 6.875rem !important;
    min-width: 6.875rem;
    padding: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &.ratio-img {
      display: flex;
    }

    img {
      margin: 0px auto;
      // padding: 7.34rem 0;
    }
  }

  .slick-list {
    margin: 0 2.5rem;
  }
  .slick-slide {
    padding: 0 0.25rem;
    align-items: stretch;
    opacity: 0;
    transition-delay: 0s;
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease-out;
    height: auto;
    > div {
      height: 100%;
    }
  }

  &__slide {
    height: 100%;
  }

  .slick-slide.slick-active {
    opacity: 1;
    transition-delay: 0.3s;
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease-in;
  }

  .slick-track {
    // display: flex;
  }

  .ratio-wrap {
    height: 100%;
  }
  .product-media-upload-react-slick {
    overflow: hidden;
    min-width: 100%;
    width: 0;
  }
}

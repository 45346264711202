@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.tooltip-block {
  display: flex;
  align-items: center;
  flex-direction: row;

  svg {
    margin-right: 0.5rem;
    path {
      fill: $gray-light-2;
    }
  }

  &__wrap {
    position: relative;
    @include style-body-small;
    color: $gray;
    cursor: pointer;

    display: flex;
    align-items: center;
    flex-direction: row;

    path {
      transition: 350ms ease fill;
    }

    &:hover {
      .tooltip {
        top: -1.2rem;
        opacity: 1;
        z-index: 9;
        visibility: visible;
        transition: 350ms ease opacity;
      }

      path {
        fill: $neutral-black;
        transition: 350ms ease fill;
      }
    }
  }
}

.tooltip {
  &--left {
    left: 2rem;
    top: -3rem;  
  }
  
  &--right {
    left: 2rem;
    top: -3rem;  
  }

  &--top {
    left: 2rem;
    top: -3rem;  
  }

  &--bottom {
    left: 2rem;
    top: -3rem;  
  }
}

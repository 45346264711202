@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.switch {
  display: flex;
  align-items: center;

  &__label {
    @include style-subtitle;
    padding-left: 0.8rem;
  }

  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 42px;
    height: 24px;
    background: $gray;
    display: block;
    border-radius: 100px;
    position: relative;
  }
  
  label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 5px;
    width: 1rem;
    height: 1rem;
    background: $white;
    border-radius: 90px;
    transition: 0.3s;
  }
  
  input:checked + label {
    background: $black;
  }
  
  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
  
  label:active:after {
    width: 15px;
  }
  
}
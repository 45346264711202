@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.upload-material {
  background-color: $neutral-white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  max-width: 62.6rem;

  @media (max-width: $tablet-breakpoint) {
    max-width: 100%;
  }

  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // flex-wrap: wrap;
  }

  &__back {
    display: flex;
    @include style-title-small;
    text-transform: uppercase;
    color: $gray;
    padding: 1.2rem 1.8rem;

    &-button {
      flex: none;
      display: flex;
      cursor: pointer;
    }
  }

  &__heading {
    @include style-title-medium;
    font-family: $optima;
    letter-spacing: 0;
    border-bottom: 1px solid $gray-light-3;
    padding: 1.5rem 2rem;
    text-transform: capitalize;
  }

  &__no-img {
    background: $drag-bg;
    border: 1px dashed $border-color;
    width: 170px;
    height: 220px;
    margin: 0px auto;
    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }
  }

  &__img {
    background: $drag-bg;
    background-position: center;
    background-size: cover;
    width: 170px;
    height: 220px;
    margin: 0px auto;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }
  }

  &__view {
    position: absolute;
    right: 24px;
    bottom: 20px;
    cursor: pointer;
  }

  &__view-container {
    display: inline-block;

    &:hover span {
      visibility: visible;
    }
    span {
      visibility: hidden;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      bottom: 100%;
      left: 50%;
      margin-left: 20px;
      text-transform: capitalize;
      width: 120px;

      @include style-body-xsmall;
      padding: 0.35rem;
      cursor: pointer;
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        transform: rotate(180deg);
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black transparent;
      }
    }
  }

  &__body {
    padding: 1rem 2rem;
  }

  &__card-header {
    @include style-title-small;
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    padding: 1rem 0;
  }

  &__txt {
    @include style-body-xsmall;
    color: $gray;
    display: flex;
    justify-content: space-between;
  }

  &__check {
    cursor: pointer;
    position: relative;
    margin-right: 1rem;
  }

  &__cta {
    display: flex;
    justify-content: center;
    margin: 1rem auto;
  }

  &__card {
    width: 220px;
    margin-right: 1rem;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

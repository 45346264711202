@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.auth-navigation {
  padding: 1rem 0;
  border: 1px solid $border-color;

  &__layout {
    display: flex;
    justify-content: space-between;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__search {
    margin-right: 2rem;
    cursor: pointer;
  }

  &__login {
    @include style-title-xsmall;
    text-transform: uppercase;
    font-size: 0.625rem;
  }

  &__logo {
    max-width: 5rem;
  }
}

@import '../../styles/utils';
@import '../../styles/variables';
@import '../../styles/mixins';

.image-grid {
  margin: 1rem 0;

  &__block {
    width: 18.75rem;

    opacity: 0;
    animation: 300ms appear 30ms ease-in-out forwards;
    transition: all 333ms ease-in-out;
    transition-delay: 100ms;
  }

  &__wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__overlay {
    border: 1px solid $color-blue;
    background-color: $bg-blue-light;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.ProductsGridView {
  padding: 2rem 0;

  &__file {
    margin-bottom: 1.6rem;
  }

  &__title {
    @include style-body-xsmall;
    color: $gray-light-2;
    font-family: $nunito-bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 1rem;
    padding-top: 1rem;
  }

  &__folder {
    cursor: pointer;
    background: $neutral-white;
    border-radius: 1px;
    margin-bottom: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.8rem 1rem;
    border-radius: 1px;
  }

  &__folder-name {
    @include style-body-xsmall;
    font-family: $nunito-bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  &__folder-img {
    padding-right: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $neutral-white;
    color: $text-color;
    padding: 1rem 0.8rem;
  }

  &__dots {
    cursor: pointer;
  }

  &__left {
    flex-grow: 1;
  }

  &__right {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__name {
    @include style-body-xsmall;
    font-family: $nunito-bold;
    padding-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  &__id {
    @include style-body-xsmall;
    font-size: 10px;
    padding-bottom: 0.5rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $gray-medium;
  }

  &__color {
    @include style-body-xsmall;
    text-transform: capitalize;
    color: $gray-medium;
  }
}

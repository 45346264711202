@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.as-folder-preview {
    width: 100%;
    background-color: $white;
    border-radius: 0.063rem;
    cursor: pointer;
    z-index: 1;

    &__heading {
        @include style-subtitle;
        background-color: $white;
        padding: 1.2rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .as-folder-preview__dots {
            opacity: 0;
            transition: 300ms ease all;
        }

        &:hover {
            .as-folder-preview__dots {
                opacity: 1;
                transition: 300ms ease all;
            }
        }
    }

    &__heading-active {
        .as-folder-preview__dots {
            opacity: 1;
            transition: 300ms ease all;
        }
    }

    &__folder-icon {
        margin-right: 1.5rem;
    }

    &__heading-flex {
        display: flex;
        justify-content: baseline;
    }

    &__dots {
        transform: rotate(-90deg);
        cursor: pointer;
    }
}
.drag-area {
  background-color: $drag-bg;
  width: 100%;

  border: 2px dashed $gray-light-2;
  @include style-body-small;
  text-align: center;

  padding: 4rem 0;
  button {
    margin-top: 1rem;
  }

  &__title {
    @include style-title-xsmall;
    text-transform: uppercase;
    font-family: $nunito-bold;
  }

  &__file-types {
    color: $gray-light-2;
    padding-bottom: 1rem;
  }
}
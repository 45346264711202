@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.controls-bar {
  width: 100%;
  border-bottom: 1px solid $controlbar-border-color;
  display: flex;
  justify-content: flex-end;
  background: #ffffff;
  &__btns {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
    height: 48px;
    align-items: center;
  }
  &__item {
    @include style-heading-small;
    margin-left: 1rem;
    text-transform: uppercase;
    img {
      border: 0;
    }
  }

  &__redo,
  &__undo {
    opacity: 0.3;
    cursor: pointer;
  }

  &__button {
    padding: 0.5rem 1.2rem;
  }
  &__btns-group {
    display: flex;
    margin-right: 6.5rem;
  }
}

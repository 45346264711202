@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.breadcrumbs {
  &__content {
    margin: 1rem auto;
  }

  &__list {
    display: flex;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    list-style: none;

    @media (max-width: $tablet-breakpoint) {
      padding: 0;
    }
  }

  &__item {
    text-transform: uppercase;
    color: $gray-light-2;
    list-style: none;
    padding-right: 0.313rem;

    &-link {
      cursor: pointer;
    }
  }

  &__previous {
    padding-right: 0.313rem;
  }

  &__group {
    @include style-body-xsmall;
    font-size: 10px;
    font-family: $nunito-bold;
    display: flex;
    flex-direction: row;
    letter-spacing: 0.1em;
  }

  &__current {
    @include style-body-xsmall;
    font-size: 0.625rem;
    letter-spacing: 0.1em;

    color: $neutral-black;
    font-family: $nunito-bold;
  }
}

@import '../../styles/utils';
@import '../../styles/variables';
@import '../../styles/mixins';

.video-block {
  &__default {
    background-image: url('../../assets/images/mm_logo_final_black.png');
    background-repeat: no-repeat;
    background-size: 10vw;
    background-position: center;
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__player-wrap {
    position: relative;
    padding-bottom: 56.2%;
    height: 0;
  }

  &__playIcon {
    @include center(both);
    cursor: pointer;
  }

  &__content {
    position: absolute;
    @include center(both);
    top: 80%;
    text-align: center;

    @media (max-width: $tablet-breakpoint) {
      top: 45%;
    }
  }

  &__headline {
    position: relative;
    letter-spacing: 0.075rem;
    text-transform: uppercase;
    margin-bottom: 3.125rem;
    font-family: $nunito-medium;
    text-align: center;
    @media (max-width: $tablet-breakpoint) {
      margin-bottom: 1rem;
    }
  }

  &__btn {
    position: relative;
    font-family: $nunito-medium;
  }

  &__play-bottom {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    padding: 1.3rem 1.875rem;
    text-transform: uppercase;
    letter-spacing: 0.075rem;
    font-size: 0.75rem;
    align-items: center;
    justify-self: start;
    cursor: pointer;
    width: 100%;
    @media (max-width: $tablet-breakpoint) {
      padding: 1rem;
    }
  }

  &__play-triangle {
    margin-right: 0.625rem;
    max-width: 1rem;
  }
}

.react-player__play-icon {
  display: none;
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.upload-modal {
  &__thumbanail {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $neutral-white;
    border: 1px solid $border-color;
    overflow: hidden;
    @media (max-width: $tablet-breakpoint) {
      width: 100px;
      height: 100px;
    }
  }

  &__txt {
    @include style-body-xsmall;
    text-align: center;
    color: $gray;
  }

  &__drag-txt {
    @include style-body-xsmall;
    font-family: $nunito-bold;

    letter-spacing: 0.1em;
    font-size: 10px;
    text-transform: uppercase;
    padding-bottom: 0.2rem;
  }

  &__file-type {
    color: $gray-light-2;
  }

  &__item-added {
    max-width: 120px;
    height: 120px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $neutral-white;
    border: 1px solid $border-color;
    overflow: hidden;
    @media (max-width: $tablet-breakpoint) {
      width: 100px;
      height: 100px;
    }

    @media (max-width: $tablet-breakpoint) {
      max-width: 100px;
    }
    &:last-child {
      margin-right: 0;
    }

    progress {
      width: 94%;
      background-color: $black-2;
      height: 5px;
      bottom: 22px;
      position: relative;
      margin: 0 0.2rem;
    }
  }

  &__items-added {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__loading {
      justify-content: center;
    }
  }

  &__drag-area {
    border: 2px dashed $gray-light-2;
    @include style-body-small;
    text-align: center;

    padding: 6rem 0;
    button {
      margin-top: 1rem;
    }
  }

  &__close {
    cursor: pointer;
  }

  &__btn-error {
    @include style-body-xsmall;
    font-synthesis: 10px;
    font-family: $nunito-bold;
    color: $warning-color-2;
    letter-spacing: 0.1em;
  }

  &__error {
    @include style-body-xsmall;
    position: absolute;
    width: 120px;
    height: 120px;
    display: flex;
    border: 1px solid $border-color;
    background: rgba(0, 0, 0, 0.5);
    flex-direction: column;
    padding: 0 0.5rem;
    margin-right: 1rem;

    @media (max-width: $tablet-breakpoint) {
      width: 100px;
      height: 100px;
    }
    img {
      width: 1em;
      height: 1em;
      align-self: flex-end;
      margin-top: 10px;
      margin-right: 3px;
      display: flex;
      cursor: pointer;
    }
    span {
      @include style-body-xsmall;
      display: flex;
      flex-direction: column;
      justify-content: right;
      align-items: center;
      color: $warning-color-2;
      font-size: 14px;
      font-family: $nunito-bold;
      margin-top: 15px;
      img {
        width: 1em;
        height: 1em;
      }
    }

    p {
      text-align: center;
      color: $warning-color-2;
    }

    button {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      margin-top: 0.5rem;
    }
  }
}

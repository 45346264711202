.react-calendar {
  border: 0 !important;
  @include style-body-small;

  &__navigation {
    position: relative;
  }

  &__navigation__label__labelText {
    @include style-body-small;
    font-family: $nunito-bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  &__navigation button:hover, 
  &__navigation button:focus {
    background-color: $white;
  }

  &__tile--active {
    background-color: $black !important;
    color: $white !important;
  }

  &__tile--active:enabled:hover,
  &__tile--active:enabled:focus  {
    background-color: $black !important;
  }

  &__navigation button {
    &:hover,
    &:focus {
      background-color: $white;
    }
  }

  &__month-view__days__day--weekend {
    color: $black;
  }

  &__tile {
    border-radius: 100%;
    height: 2.714rem !important;

    &:hover {
      background-color: $black;
    }
  }

  &__tile--now {
    background-color: $gray-light-3 !important;
    color: $black !important;
  }

  &__month-view__weekdays__weekday {
    text-transform: initial;
    font-size: 12px;
    font-family: $nunito-medium;
  }

  &__tile {
    font-size: 12px;
    font-family: $nunito-medium;
  }

  &__navigation__next2-button,
  &__navigation__prev2-button {
    display: none;
  }

  abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
  } 

  &__tile--hasActive {
    background-color: $black !important;
    color: $white !important;
    border-radius: 0 !important;
  }

  &__year-view__months__month:enabled:hover,
  &__year-view__months__month:enabled:focus {
    background-color: transparent !important;
  } 

  &__navigation__label {
    background-color: transparent !important;
  }

  &__navigation__next-button {
    color: white !important;

    &:enabled:hover,
    &:enabled:focus {
      background-color: transparent !important;
    }

    &:before {
      content: '';
      background:url('../../assets/icons/angle-right-gray.svg');
      width: 1rem;
      height: 1rem;
      background-size: 1rem 1rem;
      position: absolute;
      top: 15px;
    }
  }

  &__navigation__arrow {
    &:enabled:hover,
    &:enabled:focus {
      background-color: transparent !important;
    } 
  }

  &__navigation__prev-button {
    color: white !important;

    &:enabled:hover,
    &:enabled:focus {
      background-color: transparent !important;
    }

    &:before {
      content: '';
      background:url('../../assets/icons/angle-left-gray.svg');
      width: 1rem;
      height: 1rem;
      background-size: 1rem 1rem;
      position: absolute;
      top: 15px;
    }
  }

}


.react-calendar__year-view .react-calendar__tile, 
.react-calendar__decade-view .react-calendar__tile, 
.react-calendar__century-view .react-calendar__tile {
  padding: 0 !important;
}

// COLORS
$neutral-white: #ffffff;
$neutral-black: #000000;

$text-color: #080707;

//Blacks
$black-2: #080707;
$black-3: #272833;
$light-black: #323232;
$audio-black: #100d0b;

//Grays
$gray: #999a9a;
$gray-light: #e7e8e7;
$gray-lighter: #e7e7ed;
$gray-light-2: #cbcbcb;
$gray-light-3: #f4f4f4;
$gray-medium: #666665;
$gray-dark: #4b4b4b;

$color-blue: #4299e1;

// Sidebar
$controlbar-border-color: #ecedec;
$sidebar-border-color: #ecedec;

$border-color: #ecedec;

//Error
$color-red: #c53030;
$color-red-2: #f56565;
$color-red-light: #fff5f5;

//Alert
$alert-bg-error: #fff5f5;
$alert-bg-warning: #fffaf0;
$alert-bg-info: #f9f9f9;
$alert-bg-success: #f0fff4;

$bg-blue-light: rgba(235, 248, 255, 0.5);

//
$notification-bell-color: #f56565;

$white: #ffffff;
$black: #000000;

//Buttons
$button-active-bg: #EBF4FF;
$button-inactive-bg: #feebc8;
$button-gray: #f9f9f9;
$warning-color: #c05621;
$warning-color-2: #b95000;
$approved: #c6f6d5;
$todo: #f0fff4;

$error-color: #c53030;
$success-color: #2f855a;
$info-color: #2a69ac;

$error-3: #fed7d7;
$info-3: #ceedff;
$warning-3: #feebc8;
$declined: #ff8c4b;
$success-3: #c6f6d5;
$success-2: #48bb78;

//Steps
$frame-bg-gray: #f9f9f9;

//
$drag-bg: #f9f9f9;

//Modal
$modal-bg-inner: #f9f9f9;
$modal-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
$modal-border: 1px solid $neutral-white;

//Dashboard
$dashboard-nav-bg: #212121;

$login-bg-color: #e5e5e5;

// Live Chat
$live-chat-green: #439e6f;

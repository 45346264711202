/* Global Utility Variables Only */

.w-100 {
  width: 100% !important;
}

.d-none {
  display: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.5 !important;
}

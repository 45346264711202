/* ANIMATIONS */

//Popups
.lightbox-enter {
  opacity: 0;
  transform: scale(0.95);
}
.lightbox-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 300ms;
}
.lightbox-exit {
  opacity: 1;
}
.lightbox-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 200ms, transform 300ms;
}

//Popups
.appear-enter {
  opacity: 0;
  transform: scale(0.95);
}
.appear-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.appear-exit {
  opacity: 1;
}
.appear-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

//Accordion
.accordion-enter {
  opacity: 0;
}
.accordion-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.accordion-exit {
  opacity: 1;
}
.accordion-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

//Fade
.fade-in-enter {
  opacity: 0;
}
.fade-in-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in-out;
}
.fade-in-exit {
  opacity: 1;
}
.fade-in-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}


//Modal

.modal-enter .modal__content {
  opacity: 0;
}
.modal-enter-active .modal__content {
  opacity: 1;
  transition: opacity 300ms;
}
.modal-exit .modal__content {
  opacity: 1;
}
.modal-exit-active .modal__content {
  opacity: 0;
  transition: opacity 300ms;
}

//Sidebar Left to Right
.slide-enter {
  opacity: 0.01;
  transform: translate(-260px, 0);
}
.slide-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 300ms ease-in;
}

.slide-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.slide-exit-active {
  opacity: 0.01;
  transform: translate(-260px, 0);
  transition: all 300ms ease-in;
}

//Sidebar Left to Right
.sidebar-items-enter {
  opacity: 0.01;
  transform: translate(-260px, 0);
}
.sidebar-items-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 300ms ease-in;
}

.sidebar-items-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.sidebar-items-exit-active {
  opacity: 0.01;
  transform: translate(-260px, 0);
  transition: all 300ms ease-in;
}


//Sidebar - slide right to left
.slide-right-to-left-enter {
  opacity: 0.01;
  transform: translate(290px, 0);
}
.slide-right-to-left-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 300ms ease-in;
}

.slide-right-to-left-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.slide-right-to-left-exit-active {
  opacity: 0.01;
  transform: translate(290px, 0);
  transition: all 300ms ease-in;
}
@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.afs-asset-review {
  padding: 1rem 0;

  .slick-list,
  .slick-slider {
    height: 60vh;
    max-height: 60vh;
    margin-bottom: 1rem;
  }
  .mediaNav {
    .slick-list,
    .slick-slider {
      height: initial;
      max-height: initial;
    }
  }

  &__img {
    width: 499px;
    height: 688px;
    object-fit: cover;
    @media (max-width: $tablet-breakpoint) {
      height: 100%;
      width: 100%;
    }
  }

  &__member-img {
    width: 2rem;
    height: 2rem;
    object-fit: cover;
    margin-right: 0.4rem;
    border-radius: 100%;
  }

  &__member {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 1rem;

    span {
      color: $gray-medium;
    }
  }

  &__img-actions {
    padding-top: 1rem;
    color: $gray;

    span {
      font-size: 0.625rem;
    }
  }

  &__info {
    display: flex;
    @include style-body-xsmall;
    color: $gray;
    cursor: pointer;
  }

  &__workflow-img {
    max-width: 25rem;
    margin: 0rem auto;
  }

  &__member-wrap {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include style-body-xsmall;
    color: $text-color;
  }

  &__workflow {
    align-items: center;
    position: relative;
    display: flex;

    .pop-up__expand {
      left: 0;
      right: unset;
      top: 2.6rem;
      width: 10.43rem;
    }
  }

  &__reference-txt {
    display: flex;
    @include style-title-xsmall;
    font-size: 0.625rem;
    text-transform: uppercase;
    @media (max-width: $tablet-breakpoint) {
      margin-top: 2.5rem;
    }
  }

  &__reference {
    color: $gray;
    padding-bottom: 3rem;
    border-bottom: 1px solid $border-color;
    padding-top: 1rem;
    @media (max-width: $tablet-breakpoint) {
      padding-bottom: 2rem;
    }

    span {
      display: flex;
    }
  }

  &__status {
    margin: 2rem 0;
    color: $gray-medium;
  }

  &__compare-block {
    display: flex;
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.add-team-member {
  border-radius: 0.25rem;
  background-color: $white;
  padding: 2.2rem 1.5rem;

  h2 {
    letter-spacing: 0;
    text-transform: capitalize;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 31.25rem;
    margin: 0px auto;
  }

  &__radio-row {
    display: flex;
    justify-content: space-between;
    @media (max-width: $tablet-breakpoint) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &__radio {
    padding-right: 0.8rem;
    @media (max-width: $tablet-breakpoint) {
      padding-bottom: 0.5rem;
    }
  }

  &__heading {
    @include style-title-medium;
    font-family: $optima;
    letter-spacing: 0;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    text-align: center;
  }

  &__accordion {
    margin-top: 1.5rem;
  }

  &__subheading {
    @include style-body-small;
    color: $gray-light-2;
    line-height: 1.5;
    padding-bottom: 3rem;
    text-align: center;
  }

  &__subtitle {
    @include style-title-xsmall;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $gray;
  }

  .button-primary {
    border: 1px solid transparent;
    min-width: 10rem;

    &:hover {
      border: 1px solid $black-2;
    }
  }

  &__field {
    margin-bottom: 1.2rem;
  }

  .modal__content {
    @media (max-width: $tablet-breakpoint) {
      position: relative;
      top: 0px;
      left: 50%;
      overflow: scroll;
      transform: translate(-50%);
    }
  }

  .modal .button-primary {
    min-width: 18.75rem;
  }

  .control--radio .control__indicator {
    border: 1px solid $gray-light-2;
  }
  .control--radio input:checked ~ .control__indicator {
    border-color: $gray;
  }

  .modal__header {
    border-radius: 0.25rem 0.25rem 0 0;
  }

  .modal__footer {
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .visible-accordion {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.8rem;

    .add-team-member__field {
      width: 50%;
      padding-right: 2rem;

      &:nth-child(2n) {
        padding-right: 0;
      }
    }
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.dashboard-forms {
  width: 50.5rem;
  background: $neutral-white;
  border-radius: 0.5rem;
  padding: 2.5rem;
  margin: 0rem auto;
  margin-bottom: 16px;
  margin-top: 1rem;

  @media (max-width: $tablet-breakpoint) {
    width: 100%;
  }

  .col-md-6 {
    padding-bottom: 0.5rem;
  }

  .control-block {
    padding-bottom: 1rem;
  }

  &__updated {
    @include style-body-regular;
    color: $gray;
    word-break: break-all;
  }

  &__block {
    margin: 0.8rem 0;
    display: flex;
    flex-direction: column;
    width: fit-content;
  }

  &__headline {
    display: flex;
    align-items: center;

    span {
      @include style-body-xsmall;
      font-family: $nunito-bold;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: $gray;
      padding-left: 1rem;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3rem;
    width: 100%;

    @media (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }
  }

  &__title {
    @include style-body-xsmall;
    font-family: $nunito-bold;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-bottom: 1px solid $gray-light;
    margin-top: 2rem;
    margin-bottom: 1rem;
    line-height: 1.6;
  }

  &__heading {
    letter-spacing: 0;
  }

  &__btns {
    @media (max-width: $tablet-breakpoint) {
      padding-top: 1rem;
    }

    button {
      margin-right: 0.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .button-primary {
    border: 1px solid $neutral-black;
    &:hover {
      border: 1px solid $neutral-black;
    }
  }
}
.divider {
  margin-top: 8px;
}

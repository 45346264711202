@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.Dropdowns {
  //Gray Icon
  .css-1yc4zyy-DropdownHandleComponent svg {
    fill: $black-2;
    position: relative;
    top: -2px;
  }

  &__gray {
    .css-1yc4zyy-DropdownHandleComponent svg {
      fill: $gray-medium;
    }
  }
}

@import '../../styles/utils';
@import '../../styles/variables';
@import '../../styles/mixins';

.modal-template {
  position: relative;

  &--overlay {
    .modal-template__header {
      position: absolute;
      top: 2rem;
      padding: 0 2rem 0 5rem;
    }
  }

  &__header {
    width: 100%;
    z-index: 9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 2rem 0 5rem;
  }

  &__logo {
    max-width: 11.25rem;
  }

  &__close {
    img {
      width: 20px;
      cursor: pointer;
    }
  }

  //
  .video-block__headline {
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    @media (max-width: $tablet-breakpoint) {
      display: none; //hidden on mobile, due to lack of spacing
    }
  }

  .video-block__btn {
    @media (max-width: $tablet-breakpoint) {
      display: flex;
      margin-top: 2.8rem;
    }
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.profile {
  cursor: pointer;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  object-fit: cover;
}

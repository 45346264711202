@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.change-password {
  padding-top: 120px;
  max-width: 440px;
  margin: 0px auto;

  &__heading {
    @include style-title-medium;
    font-family: $optima;
    letter-spacing: 0;
  }

  &__subheading {
    @include style-body-small;
    margin: 0.5rem 0 2rem 0;
    color: $gray-medium;
  }

  &__info {
    @include style-body-small;
    color: $gray-medium;
    padding-top: 0.5rem;
  }

  &__success-txt {
    @include style-title-medium;
    @include optima-font();
    letter-spacing: 0;
  }

  .button-primary {
    display: block;
    width: 100%;
    margin: 1.5rem 0;
  }

  .link-gray {
    display: flex;
    width: fit-content;
    margin: 0px auto;
  }

  .control-block {
    margin-bottom: 1.5rem;
  }

  .error-text {
    @include style-body-small;
    font-family: $nunito-bold;
    margin-top: 0.5rem;
    transition: 300ms ease all;

    &::before {
      top: 2px;
    }
  }
}

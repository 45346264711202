@import '../../styles/utils';
@import '../../styles/variables';
@import '../../styles/mixins';

.loader {
  opacity: 0;
  z-index: 102;

  &__center {
    @include center(both);
  }

  &__wrap {
    width: 32px;
    transform: rotate(-45deg);
  }

  &__line {
    margin-bottom: 10px;
    height: 2px;
    width: 0px;
    background: $black;
    animation: animation-line 1s ease-in-out alternate infinite;
    z-index: 9;

    span {
      width: 32px;
      height: 2px;
      background-color: $gray-light;
      display: block;
      z-index: -1;
      position: absolute;
    }
  }

  &__line-2 {
    animation-delay: 250ms;
  }

  &__line-3 {
    animation-delay: 450ms;
  }

  &--active {
    opacity: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    &-relative {
      position: relative;
    }
  }

  &--relative {
    position: relative;
  }
  &--bg-grey {
    background: rgba(0, 0, 0, 0.25);
  }
}

@keyframes animation-line {
  0% {
    width: 0px;
  }

  100% {
    width: 32px;
  }
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.float-right {
  float: right;
}

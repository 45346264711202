@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.accordion {
  &__heading {
    margin-top: 1rem;
    @include style-title-xsmall;
    font-size: 12px;
    text-transform: uppercase;
    color: $gray;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    margin-bottom: 1.5rem;
  }

  .modal__footer {
    border: 0;
    padding: 1.5rem 1.5rem;
  }

  .arrow-right {
    transform: rotate(0deg);
    transition: all ease 0.25s;
  }

  .arrow-bottom {
    transform: rotate(90deg);
    transition: all ease 0.25s;
  }
}

@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.asset-library-nav {
    max-width: 17rem;

    @media (max-width: $tablet-breakpoint) {
        max-width: 100%;
    }
}
@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.modal-upload-details {
  &__details {
    padding-top: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  &__row {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-bottom: 1px solid $gray-light;
  }

  &__field {
    @include style-title-small;
    text-transform: uppercase;
    color: $gray;
    display: flex;
    align-items: center;
  }

  &__detected {
    @include style-body-small;
    color: $gray-dark;
    display: flex;
    align-items: center;

    @media (max-width: $mobile-breakpoint) {
      padding-top: 0.5rem;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    padding: 0.8rem;
    border: 1px solid $gray-lighter;
    background-color: $white;
    justify-content: space-around;
  }

  &__header {
    @include style-title-small;
    margin-top: 1rem;
    background: $gray-light-3;
    color: $gray;
    padding: 0.5rem 1.2rem;
    text-transform: uppercase;
  }

  &__back {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: $gray-light-2;
    @include style-title-small;
    font-size: 14px;
    text-transform: uppercase;
    img {
      padding-right: 5px;
    }
  }

  &__name {
    width: 82%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
      justify-content: center;
      width: 75%;
    }
  }

  &__main {
    @include style-body-small;
    color: $gray-medium;
    display: flex;
    flex-direction: column;
    span {
      font-family: $nunito-bold;
      color: $black-2;
      padding-bottom: 0.3rem;
    }
  }

  .modal__header {
    border-bottom: 1px solid $border-color;
  }

  .modal__inner {
    padding: 0 0rem 1.8rem 0;
  }

  &__action {
    display: flex;
  }

  &__file {
    width: 8%;

    @media (max-width: $mobile-breakpoint) {
      width: 15%;
    }

    span {
      @include style-body-xsmall;
      background-color: $gray-light-3;
      color: $gray-light-2;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-size: 10px;
      border-radius: 4px;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__completed {
    display: flex;
    justify-content: space-evenly;
  }

  &__errors {
    white-space: pre-wrap;
  }

  .modal__inner-v2 {
    padding-bottom: 5rem;
  }

  .button-primary {
    min-width: 5.5rem;
  }

  //modifiers
  &--detailsView {
    .button-transparent-black {
      border-color: transparent;
    }

    .button-primary {
      background-color: $white;
      color: $black-2;
      border: 1px solid $black-2;

      &:hover {
        background-color: $black-2;
        color: $white;
      }
    }
  }
  &__input {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.brand-customization {
  .brand-content {
    &__background-color {
      background-color: $frame-bg-gray;
    }
    &__content {
      background-color: white;
    }
    &__preview {
      display: flex;
      flex-direction: row;
      padding: 2.75rem 2.75rem 0 0;
      .modal-template {
        width: 100%;
      }
    }
    &__menu {
      display: flex;
      position: relative;
      top: 30vh;
      justify-content: center;
      height: fit-content;
      width: 2.75rem;
      .frame__slash {
        position: initial;
        left: initial;
        top: initial;
      }
      .frame__close::before {
        display: none;
      }
    }
    &__content-background {
      margin: 0.75rem 1rem;
    }
  }

  .template_selection {
    &__boxes {
      background-color: #ecedec;
      height: 24.5rem;
      width: 24.5rem;
      position: relative;
      padding-top: 1.5rem;
    }
    &__view-icon-container {
      margin-bottom: 1.5rem;
      position: relative;
      margin-right: 1rem;
    }
    &__view-icon {
      position: absolute;
      right: 0;
    }
    &__img-top-container {
      position: relative;
    }
    &__img-top {
      margin: auto;
    }
    &__template-img {
      margin: auto;
    }
    &__button {
      margin: 1rem 1.5rem;
    }
  }

  .sidebar {
    border-right: 2px solid #f4f4f4; 
    box-shadow: 3px 0px 2px rgba(23, 43, 77, 0.04), 5px 0px 3px rgba(23, 43, 77, 0.04); 
    z-index: 10;
    width: 20rem;
    height: auto; 
    &__heading {
      padding: 0;
    }

    &__item-wrapper {
      margin: 40px 0;
    }
    &__item-heading {
      padding: 0;
    }
    &__item {
      padding: 0;
    }

    &__subitem-wrapper {
      margin: 24px 0 40px;
    }
    &__subitem-heading {
      padding: 0;
    }
    &__subitem {
      padding: 0;
    }

    &__item-name {
      margin: 16px 0 24px;
      padding: 0;
    }
    &__wrap {
      padding: 0;
    }

    .dashboard-forms__block,
    .control-block {
      margin: 16px 0 24px;
      width: 100%;
    }
    .control--label {
      margin: 0;
    }
    .profile-img {
      border-radius: 0;
      height: 40px;
      margin: 0;
    }
    .image-upload {
      margin-top: 16px;
    }
  }
}

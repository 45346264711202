@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.ProductsList {
  .pop-up__expand {
    width: 120px;
  }

  .pagination-info {
    @include style-body-small;
    color: $gray;
  }

  &__selection {
    @include style-body-small;
    background-color: $light-black;
    padding: 0.5rem 1rem;
    width: 100%;
    color: $gray-light-2;
    display: flex;
    position: relative;

    img {
      margin-right: 1rem;
      cursor: pointer;
    }

    span {
      cursor: pointer;
    }

    .delete-products {
      position: absolute;
      right: 0;
    }
  }
}

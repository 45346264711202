@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.virtual-runway-header {
  padding: 0.4rem 0;
  background-color: $white;
  border-bottom: 1px solid $gray-light;
  color: $gray;
  @media (max-width: $tablet-breakpoint) {
    position: relative;
    top: -1rem;
  }

  .atelier {
    &__wrap {
      padding: 0;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.7rem 0 0;

    @media (max-width: $tablet-xl-breakpoint) {
      flex-direction: column;
      align-items: baseline;
    }
  }

  &__title {
    @include style-subtitle;
    color: $black;
    padding-right: 0.5rem;
  }

  &__heading {
    border-left: 1px solid $gray-light-3;
    padding: 0.8rem 1rem;
    @include style-subtitle;
    color: $gray-medium;
    margin-left: 0.6rem;
  }

  &__right-aligned {
    display: flex;
    margin-right: 5px;

    @media (max-width: $tablet-xl-breakpoint) {
      padding-top: 1rem;
      flex-direction: column;
      align-items: baseline;
      justify-content: flex-start;
      padding-bottom: 0.5rem;
    }
  }

  &__history {
    margin-right: 32px;
    cursor: pointer;
  }

  button {
    &:focus {
      outline: none;
    }
  }

  &__user-icon {
    cursor: pointer;
    img {
      margin-right: 0.5rem;
    }
  }

  &__left-aligned {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    @media (max-width: $tablet-xl-breakpoint) {
      padding-bottom: 1rem;
    }
  }

  .virtual-runway-date {
    @media (max-width: $tablet-xl-breakpoint) {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }

  .button-dashboard img {
    opacity: 0.5;
  }
}

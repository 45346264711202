@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.image-crop-modal {
  p {
    @include style-body-regular;
  }

  .modal__title {
    @include style-title-small;
    color: $gray;
    text-transform: uppercase;
    font-size: 10px;
  }

  .modal__inner {
    padding-bottom: 0;
  }

  .modal__footer {
    top: -1px;
    position: relative;
    justify-content: center;
  }
}

.button__replace-file__container {
  margin-right: 1rem;
  &:hover {
    .btn-upload-img {
      filter: invert(1) brightness(100);
    }
  }
}
.button__replace-file {
  border-radius: 1px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus,
  &:visited,
  &:active {
    outline: 0;
  }
}

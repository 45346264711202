// Global Form Wrap
.form-block {
  padding-bottom: 0.1rem;
}

//Text
.control-block {
  margin: 0.8rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  &--error {
    input {
      background: $color-red-light;
      border: 1px solid $color-red-2;
    }
  }

  &--success {
    input {
      background: $alert-bg-success;
      border: 1px solid $success-color;
    }
  }
}

.control-group {
  margin: 0.8rem 0;
}

//Checkbox
.control {
  &--full-width {
    .react-dropdown-select,
    .react-dropdown-select-dropdown {
      width: 100% !important;
      font-family: $nunito-regular;

      &:focus-within {
        outline: none;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
      }
    }

    .control--text {
      width: 100%;
    }

    .react-dropdown-select-input {
      &::placeholder {
        @include style-body-small;
        color: $gray-light;
      }
    }
  }
  //Radio
  &--radio {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;
    align-items: center;
    @include style-body-xsmall;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .control__indicator:after {
        display: block;
      }

      //Disabled
      &:disabled {
        opacity: 0;
        pointer-events: none;
        ~ .control__indicator,
        &:checked ~ .control__indicator {
          opacity: 0.3;
        }
      }
    }

    input:checked ~ .control__indicator {
      background: $neutral-white;
      border: 4px solid $black-2;
      transition: 200ms ease all;
    }

    .control__indicator {
      border: 1px solid $gray-light-2;
      transition: 200ms ease all;
      border-radius: 2px;

      &::after {
        left: 1rem;
        top: 1rem;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: #fff;
      }
    }

    .control__indicator {
      border-radius: 50%;
    }
  }

  &--help {
    @include style-body-small;
    color: $gray-medium;
    margin-top: 0.3rem;
  }

  &--required {
    &:after {
      content: '*';
      color: $warning-color;
      margin-left: 0.3rem;
    }
  }

  &--text {
    max-width: 20rem;
    background: $neutral-white;
    border: 1px solid $gray-light;
    box-sizing: border-box;
    border-radius: 1px;
    height: 2.5rem;
    padding: 0.5em 0.5rem;
    width: 100%;
    min-width: 100%;
    &::placeholder {
      @include style-body-small;
      font-size: 1rem;
      color: $light-black;
    }
    &:focus,
    &:active {
      outline: none;
    }
    font-family: $nunito-regular;
    color: $light-black;
  }

  &--textarea {
    @extend .control--text;
    height: 6rem;
    font-size: 1rem;
    &::placeholder {
      @include style-body-small;
      color: $gray-light-2;
    }
  }

  &--label {
    @include style-body-xsmall;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-family: $nunito-medium;
    text-align: left;
  }

  &--select {
    background: white;
  }

  //Checkbox
  &--checkbox {
    position: relative;
    display: inline-grid;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    text-transform: uppercase;
    width: max-content;
    margin: 0.3rem 0;
    display: flex;
    align-items: center;
    @include style-body-xsmall;
    letter-spacing: 0.1em;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      //Disabled
      &:disabled {
        opacity: 0;
        pointer-events: none;
        ~ .control__indicator,
        &:checked ~ .control__indicator {
          opacity: 0.2;
        }
      }
      &:checked ~ .control__indicator {
        background-color: $neutral-black;
        border-radius: 1px;
        -webkit-transform: rotate(0deg) scale(1);
        -ms-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
        opacity: 1;
        border: 1px solid $neutral-black;
      }

      &:checked ~ .control__indicator::after {
        transform: rotate(45deg) scale(1);
        opacity: 1;
        left: 0.313rem;
        top: 1px;
        width: 0.25rem;
        height: 0.625rem;
        border: solid $neutral-white;
        border-width: 0 1px 1px 0;
        background-color: transparent;
        border-radius: 0;
      }
    }
  }

  &__indicator {
    height: 1rem;
    width: 1rem;
    background-color: transparent;
    border-radius: 1px;
    transition: all 0.3s ease-out;
    border: 1px solid $gray-light-2;
    margin-right: 0.5rem;

    &::after {
      position: absolute;
      content: '';
      left: 0.5rem;
      top: 0.5rem;
      height: 0px;
      width: 0px;
      border-radius: 1px;
      border: solid $neutral-white;
      border-width: 0 3px 3px 0;
      transform: rotate(0deg) scale(0);
      opacity: 1;
      transition: all 0.3s ease-out;
    }
  }
}

//Phone input overrides
.react-tel-input {
  font-family: $nunito-regular !important;
  font-size: 14px;
  color: $light-black;
  width: 352px !important;
  margin: 0px;
  @media (max-width: $tablet-breakpoint) {
    width: 100% !important;
  }

  .form-control {
    border-radius: 0px !important;
    border: 1px solid $gray-light !important;
    width: 100% !important;
    height: 40px !important;
  }

  .selected-flag,
  .flag-dropdown {
    border-radius: 0px !important;
    transition: 200ms ease all;

    &:hover {
      transition: 200ms ease all;
    }
  }
  &::placeholder {
    @include style-body-small;
    font-size: 1rem;
    color: $light-black;
  }

  .flag-dropdown {
    width: 456px;
    background-color: $button-gray !important;
    border: 1px solid $gray-light !important;
    width: auto;
  }

  .country-list {
    margin: 1px 0 0px -1px !important;
    box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.12) !important;
    border-radius: 2px !important;
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.ProductInfoSideBar {
  position: fixed;
  height: calc(100vh - 7.6rem);
  width: 288px;
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  z-index: 7;
  top: 7.7rem;
  right: 3.1rem;

  @media (max-width: $tablet-breakpoint) {
    right: 0;
    top: 4.95rem;
  }

  &__wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  &__body {
    border-top: 1px solid $border-color;
    overflow-y: scroll;
    width: 100%;
    padding: 16px;
  }

  &__collectionName {
    border-bottom: 1px solid #E7E8E7;
    width: 100%;
    height: 12rem;
  }

  &__memberAccess {
    margin-top: 16px;
    color: #CBCBCB;
    font-size: 12px;
    line-height: 15.6px;
    font-weight: 700;
    letter-spacing: 0.09em;
    font-style: normal;
    text-transform: uppercase;
  }

  &__profile {
    display: flex;
    padding-top: 16px;
    width: 55%;
    justify-content: space-between;
  }

  &__ManageAccess {
    margin-top: 16px;
    // border: 2px solid #C53030;
    width: 185px;
    height: 56px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    color: #999A9A;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

}
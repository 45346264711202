@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.style-summary {
  &__block {
    padding: 16px 0 3rem;

    & .side-navigation {
      padding-top: 9px;
    }
  }

  &__col-1 {
    padding: 3rem 1.6rem;
    @media (min-width: $desktop-breakpoint) {
      padding: 3rem 0rem 3rem 3rem;
    }
  }

  &__col-2 {
    padding: 3rem 1rem;
  }

  &__col-3 {
    padding: 0 2rem;
    background: $white;
  }

  &__slideshow {
    .mediaNav {
      .slick-list,
      .slick-slider {
        height: initial;
        max-height: initial;
      }
    }
  }

  &__designer-role {
    .product-upload__drag-area {
      border: none;
    }
  }

  .product-upload__drag-area {
    height: 100%;
    margin: 0;
    width: initial;
  }

  .mediaNav {
    .product-upload__drag-area {
      height: initial;
    }
  }

  &__product-name {
    @include style-title-medium;
    text-transform: uppercase;
    font-size: 1.25rem;
    padding-top: 1.6rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
  }

  &__vs-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 2rem;
  }

  &__img-lg {
    display: block;
    width: 100%;
    border: 1px dashed $gray-light;
    background: #f9f9f9;

    &.style-summary__drop-zone {
      padding: 10.34rem 0;
    }

    img {
      margin: 0px auto;
      padding: 7.34rem 0;
      cursor: pointer;
    }

    img.asset__loaded {
      height: 33.9rem;
      padding: 0;
      width: 100%;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    height: 100% !important;
    height: 100%;
    cursor: pointer;
  }

  &__vs-title {
    @include style-title-small;
    text-transform: uppercase;
    padding-top: 0.5rem;
  }

  &__vsm-drop-icon {
    img.style-summary__img-lg {
      padding: 10.73rem;

      &.asset__loaded {
        padding: initial;
      }
    }
  }

  &__vs-single {
    display: flex;
    flex-direction: column;
    @media (max-width: $tablet-breakpoint) {
      padding-bottom: 1rem;
    }

    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;

    img {
      padding: 3.5rem;
    }

    &__drop-zone {
      img {
        padding: initial;
      }
    }
    .style-summary__img-sm {
      padding: initial;
    }

    img {
      &.refLoaded {
        padding: initial;
        width: 7.5rem;
        height: 10rem;
        object-fit: cover;
        background: #f9f9f9;
      }
    }
  }

  &__img-sm {
    width: 7.5rem;
    height: 10rem;
    object-fit: cover;
    padding: initial;
  }

  &__workflow {
    @include style-body-xsmall;
    font-size: 10px;
    font-family: $nunito-bold;
    display: flex;
    flex-direction: row;
    &__label {
      letter-spacing: 1px;
      margin-right: 0.75rem;
      margin-bottom: 1rem;
    }
    &__button {
      &::before {
        margin-right: 0.75rem;
        content: url('../../../assets/icons/Icon-info-circle-full.svg');
      }
    }
  }
  &__info {
    @include style-title-xsmall;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $gray-dark;
    padding-bottom: 1rem;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__info:hover {
    overflow: visible;
    white-space: normal;
    height: auto; /* just added this line */
  }

  &__box-small {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;

    .style-summary__gray-sm {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__title {
    @include style-title-xsmall;
    color: $gray;
    margin-right: 0.5rem;
    font-size: 0.75rem;
  }

  &__progress {
    padding: 2rem;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid #f4f4f4;
    max-width: 21.75rem;
  }
  &__virtual-sample__heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: $white;
    padding-top: 1rem;

    input[type='file'] {
      display: none;
    }
    label[for='batchUploadPlus'] {
      cursor: pointer;
    }
  }
  &__white-area {
    height: 100%;
    background-color: $white;
    margin: 0 0 2rem 0;
    padding: 1rem 3rem;
    @media (max-width: $tablet-breakpoint) {
      padding: 1rem;
    }
  }

  &__heading {
    @include style-title-small;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-top: 0;
  }
  .button-badge {
    margin-bottom: 1rem;
  }
  .button-dashboard {
    .style-summary_cta {
      padding: 0.5rem;
    }
  }

  &__subheading {
    @include style-body-xsmall;
    color: $gray;
    padding: 0.8rem 0;
  }

  &__progress-item {
    @include style-heading-small;
    color: $gray;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }

  &__progress-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      padding-right: 1rem;
      cursor: pointer;
    }
  }

  &__view-current {
    cursor: pointer;
  }
}

.image-gallery-thumbnail {
  white-space: nowrap;
  text-overflow: ellipsis;
}

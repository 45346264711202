// FONTS

//Nunito

@font-face {
  font-family: 'NunitoSans-Bold';
  src: url('../assets/fonts/Nunito/NunitoSans-Bold.woff') format('woff'),
    url('../assets/fonts/Nunito/NunitoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans-Regular';
  src: url('../assets/fonts/Nunito/NunitoSans-Regular.woff') format('woff'),
    url('../assets/fonts/Nunito/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans-SemiBold';
  src: url('../assets/fonts/Nunito/NunitoSans-Bold.woff') format('woff'),
    url('../assets/fonts/Nunito/NunitoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans-Light';
  src: url('../assets/fonts/Nunito/NunitoSans-Bold.woff') format('woff'),
    url('../assets/fonts/Nunito/NunitoSans-Bold.ttf') format('truetype');
}

//Paratype
@font-face {
  font-family: 'ParaTypeSans';
  src: url('../assets/fonts/Paratype/ParaTypeSans.woff') format('woff'),
    url('../assets/fonts/Paratype/ParaTypeSans.ttf') format('truetype');
}

//Styrene
@font-face {
  font-family: 'Styrene-Regular';
  src: /* IE6-IE8 */ url('../assets/fonts/Styrene/Styrene-B-Regular.otf')
    format('opentype');
  font-weight: normal;
}

//Optima

//Italic
@font-face {
  font-family: 'Optima-Italic';
  src: url('../assets/fonts/Optima/OptimaItalic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Optima/OptimaItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/Optima/OptimaItalic.otf') format('opentype'),
    /* Open Type Font */ url('../assets/fonts/Optima/OptimaItalic.svg') format('svg'),
    /* Legacy iOS */ url('../assets/fonts/Optima/OptimaItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/Optima/OptimaItalic.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Optima/OptimaItalic.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Optima-Medium';
  src: url('../assets/fonts/Optima/OptimaMedium.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Optima/OptimaMedium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/Optima/OptimaMedium.otf') format('opentype'),
    /* Open Type Font */ url('../assets/fonts/Optima/OptimaMedium.svg') format('svg'),
    /* Legacy iOS */ url('../assets/fonts/Optima/OptimaMedium.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/Optima/OptimaMedium.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Optima/OptimaMedium.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

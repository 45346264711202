@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.frame {
  .share-model-modal,
  .collection-access-modal {
    &__label {
      .control--label {
        text-transform: none;
        font-family: $nunito-regular;
        letter-spacing: 0;
        color: $gray-dark;
      }
    }

    &__add-btn {
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem 0;
    }

    &__members {
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 1px solid $gray-light;
    }

    &__member {
      @include style-body-small;
      display: flex;
      justify-content: space-between;
      padding: 0.8rem 0;
      @media (max-width: $tablet-breakpoint) {
        flex-direction: column;
      }
    }

    &__member-name,
    &__member-email,
    &__member-access {
      width: 30%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__member-name:hover,
    &__member-email:hover,
    &__member-access:hover {
      overflow: visible;
      white-space: normal;
      height: auto; /* just added this line */
    }

    &__member-email {
      display: flex;
      align-items: center;
      color: $gray;
      @media (max-width: $tablet-breakpoint) {
        padding: 0.5rem 0rem 0.5rem 3.2rem;
      }
    }

    &__member-name {
      display: flex;
      align-items: center;
    }

    &__member-access {
      display: flex;
      align-content: flex-end;
      justify-content: flex-end;
      position: relative;
      @media (max-width: $tablet-breakpoint) {
        padding: 0.5rem 0rem 0.5rem 3.2rem;
      }
    }

    .pop-up__expand {
      top: 1rem;
      left: 0rem;
    }

    &__member-photo {
      width: 2.2rem;
      height: 2.2rem;
      text-transform: capitalize;
      min-width: 2.2rem;
      min-height: 2.2rem;
      border-radius: 100%;
      object-fit: cover;
      margin-right: 1rem;
      background-color: $gray;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $nunito-bold;
      letter-spacing: 0;
    }

    .modal__inner {
      padding-top: 0;
    }

    .select__control {
      background: $neutral-white;
      border: 1px solid $gray-light;
      box-sizing: border-box;
      border-radius: 1px;
      min-height: 2.5rem;
      width: 100%;
      min-width: 100%;
      &::placeholder {
        @include style-body-small;
        font-size: 1rem;
        color: $light-black;
      }
      &:focus,
      &:active {
        outline: none;
      }
      font-family: $nunito-regular;
      color: $light-black;
      padding: 0em 0.5rem;
      margin: 0.5rem 0;
    }
    .select__placeholder {
      font-family: $nunito-regular;
      color: $light-black;
      @include style-body-small;
      font-size: 1rem;
      color: $light-black;
    }
    .select__value-container {
      .select__value-container--is-multi {
      }
    }
    .select__indicator-separator {
      display: none;
    }
  }
}

.modal-materialLibrary {
  margin: 2rem 0;

  @media (max-width: $tablet-breakpoint) {
    padding: 0 1rem;
  }

  &__item {
    overflow: hidden;
    cursor: pointer;
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition-timing-function: ease-in;
    transition: 500ms;
    overflow: hidden;

    &:hover {
      overflow: hidden;
      @include imageEffectHoverState;
    }
  }

  &__content {
    height: auto;
    display: block;
    margin-bottom: 3rem;
  }

  &__headline {
    color: $black-2;
    @include style-body-small;
    font-family: $nunito-bold;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 12px;
  }

  &__subheading {
    @include style-body-small;
    font-weight: 300;
    color: $gray-medium;
    max-width: 70%;
  }
}

@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.login-form {
  background-color: $white;
  width: 29.375rem;
  padding: 0 2.5rem;
  padding-bottom: 3rem;

  @media (max-width: $tablet-breakpoint) {
    padding: 1rem 1rem;
  }

  &__logo {
    max-width: 12.5rem;
    padding-bottom: 2rem;
  }

  &__header {
    display: flex;
    justify-content: center;
    padding: 5rem 0 2rem 0;

    @media (max-width: $tablet-breakpoint) {
      padding: 2rem 0;
    }
  }

  .control--checkbox {
    font-size: 0.625rem;
  }

  .button-primary,
  .button-transparent-black {
    width: 100%;
    margin-bottom: 1rem;
  }

  .button-primary {
    border: 1px solid $black-2;
    &:hover {
      border: 1px solid $black-2;
    }
  }

  .error-text {
    @include style-body-small;
    font-family: $nunito-bold;

    &::before {
      top: 2px;
    }
  }

  &__links {
    display: flex;
    flex-direction: row;
    @media (max-width: $tablet-breakpoint) {
      flex-direction: column;
      padding: 1rem 0;
    }
  }

  &__link {
    @include style-title-small;
    font-size: 0.75rem;
    color: $gray-light-2;
    text-transform: uppercase;
    margin-right: 0.5rem;
    transition: 350ms ease color;

    &:hover {
      color: $black-2;
      transition: 350ms ease color;
    }

    @media (max-width: $tablet-breakpoint) {
      font-size: 0.625rem;
      padding-bottom: 0.313rem;
    }
  }

  &__heading {
    @include style-title-medium;
    letter-spacing: 0;
    font-family: $optima;
    padding-bottom: 1rem;
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.pagination {
  display: flex;
  flex-direction: row;
  @media (max-width: $tablet-breakpoint) {
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }

  &__detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
      justify-content: center;
      padding-bottom: 1rem;
    }

    &-text {
      @include style-body-small;
      padding-left: 1rem;
      color: $gray;
    }
  }

  &__controller {
    width: 75%;
    @media (max-width: $tablet-breakpoint) {
      width: 100%;
      justify-content: center;
      display: grid;
      padding-top: 1rem;
      padding-bottom: 2rem;
    }

    ul {
      display: flex;
      list-style-type: none;

      li {
        font-family: $nunito-medium;
        color: $light-black;
        padding: 4px 12px;
        &.selected {
          background-color: #efeff0;
        }
        a,
        a:focus,
        a:visited,
        a:active {
          border: 0;
          outline: 0;
        }
      }
    }

    &-prev {
      position: relative;
      top: 2px;
      padding-right: 1rem;

      &::before {
        content: url('../../../assets/icons/angle-left.svg');
      }
    }
    &-next {
      padding-left: 1rem;
      position: relative;
      top: 2px;

      &::before {
        content: url('../../../assets/icons/angle-right.svg');
      }
    }
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.customize-sign-off {
  margin: 0 0 1rem;
  background-color: $white;
  box-shadow: 0px 4px 24px rgba(8, 7, 7, 0.05);
  border-radius: 0.5rem;

  @media (max-width: $tablet-breakpoint) {
    margin-top: 1rem;
  }

  .product-upload__header {
    padding: 2rem 2.5rem;
    justify-content: space-between;
    border-bottom: 0.063rem solid $gray-light-3;

    @media (max-width: $tablet-breakpoint) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .product-upload__heading {
    @media (max-width: $tablet-breakpoint) {
      padding: 0;
    }
  }

  .product-upload__controls {
    padding: 0;
  }

  &__maxHeight {
    height: 80vh;
    @media (max-width: $tablet-breakpoint) {
      height: 100%;
    }
  }

  &__scrollable {
    overflow: scroll;
    height: 70vh;
  }

  &__wrap {
    display: flex;
    flex-direction: row;
    @media (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }
  }
  &__block {
    border-right: 0.063rem solid $gray-light-3;
    width: 50%;
    padding: 1rem 2.5rem;

    &:first-child {
      background-color: $frame-bg-gray;
      padding: 1rem 1rem 1rem 2.5rem;
      @media (max-width: $tablet-breakpoint) {
        padding: 1rem;
      }
    }

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
      border-right: 0;
      border-bottom: 0.063rem solid $gray-light-3;
    }

    &:last-child {
      border-right: 0;
    }
  }

  &__heading {
    @include style-heading-small;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $black-2;
    padding-top: 1.2rem;
    padding-bottom: 1rem;
  }

  .product-slider {
    background-color: $white;
    margin-top: 1rem;
  }
}

// typography utils
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// transitions
@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition: $what $time $how;
  -ms-transition: $what $time $how;
  -o-transition: $what $time $how;
  transition: $what $time $how;
}

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Body Text */

//12px
@mixin style-body-xsmall {
  @include font-smoothing;
  font-family: $nunito-regular;
  font-size: 0.75rem;
  line-height: 1rem;
}


//14px
@mixin style-body-small {
  @include font-smoothing;
  font-family: $nunito-regular;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

//16px
@mixin style-body-regular {
  @include font-smoothing;
  font-family: $nunito-regular;
  font-size: 1rem;
  line-height: 1.5rem;
}

//18px
@mixin style-body-large {
  @include font-smoothing;
  font-family: $nunito-regular;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

//20px
@mixin style-body-x-large {
  @include font-smoothing;
  font-family: $nunito-regular;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

/* Titles */

//10px
@mixin style-title-xsmall {
  @include font-smoothing;
  font-family: $nunito-bold;
  letter-spacing: 0.1em;
  font-size: 0.625rem;
  line-height: 1.875rem;
}

//24px
@mixin style-title-medium {
  @include font-smoothing;
  font-family: $nunito-bold;
  letter-spacing: 0.1em;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

//14px
@mixin style-title-small {
  @include font-smoothing;
  font-family: $nunito-bold;
  letter-spacing: 0.1em;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

// 12px
@mixin style-subtitle {
  @include font-smoothing;
  font-family: $nunito-bold;
  letter-spacing: 0.1em;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
}

@mixin style-title-regular-optima {
  @include font-smoothing;
  font-family: $optima;
  letter-spacing: 0;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: normal;
}

//28px
@mixin style-title-regular {
  @include font-smoothing;
  font-family: $nunito-bold;
  letter-spacing: 0.1em;
  font-size: 1.75rem;
  line-height: 2.25rem;
}

//36px
@mixin style-title-large {
  @include font-smoothing;
  font-family: $nunito-bold;
  letter-spacing: 0.1em;
  font-size: 2.25rem;
  line-height: 3.375rem;
}

@mixin paratype-font {
  font-family: $paratype;
}


@mixin optima-font {
  font-family: $optima;
}

//10px
@mixin style-heading-small {
  @include font-smoothing;
  font-family: $nunito-bold;
  letter-spacing: 0.1em;
  font-size: 0.625rem;
}

@mixin txt-gray {
  @include font-smoothing;
  font-family: $nunito-regular;
  font-size: 0.875rem;
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.atelier {
  &__wrap {
    padding: 0 3rem;
  }
}

.style-sample-review-header {
  padding: 0.4rem 0;
  background-color: $white;
  border-bottom: 1px solid $gray-light;
  color: $gray;
  @media (max-width: $tablet-breakpoint) {
    position: relative;
    top: -1rem;
  }

  &__title {
    @include style-subtitle;
    color: $black;
    padding-right: 0.5rem;
  }

  &__wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.7rem 0 0;

    @media (max-width: $tablet-xl-breakpoint) {
      flex-direction: column;
      align-items: baseline;
    }
  }

  &__heading {
    border-left: 1px solid $gray-light-3;
    padding: 0.8rem 1rem;
    @include style-subtitle;
    color: $gray-medium;
    margin-left: 0.6rem;
  }

  &__back {
    cursor: pointer;
  }

  &__right-aligned {
    display: flex;
    margin-right: 5px;

    @media (max-width: $tablet-xl-breakpoint) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      padding-bottom: 0.5rem;
    }
  }

  &__left-aligned {
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

  .button-dashboard {
    position: relative;
    padding: 0.5rem;
    margin-left: 18px;

    &:hover {
      background-color: $button-gray;
      .tooltip {
        visibility: visible;
        opacity: 1;
        transition: 200ms ease all;
      }
    }

    &:first-child {
      .tooltip {
        // min-width: 8.5rem;
      }
    }
  }

  .tooltip--black {
    min-width: 6rem;
    text-align: center;

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -0.313rem;
      border-width: 0.313rem;
      border-style: solid;
      border-color: $black-2 transparent transparent transparent;
      transform: rotate(180deg);
    }
  }

  button {
    border: 0.063rem solid transparent;
    transition: 250ms ease all;

    &:hover {
      transition: 250ms ease all;
      border: 0.063rem solid $black;
    }
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.contact-cards {
  &__card {
    background-color: $white;
    border: 1px solid $gray-light-3;
    border-radius: 8px;
    padding: 1rem;
  }

  &__avatar {
    border-radius: 100%;
    font-family: $nunito-bold;
    font-size: 0.8rem;
    background-color: $gray-light-2;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.2rem;
    min-width: 2.2rem;
    min-height: 2.2rem;
    height: 2.2rem;
    object-fit: cover;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-right: 0.6rem;
  }

  &__cell {
    @include style-body-small;
    display: flex;
    align-items: center;
    color: $gray;
    width: 100px;

    @media (max-width: $tablet-xxl-breakpoint) {
      padding: 0.5rem 0;
    }

    &:nth-child(1) {
      width: 150px;
    }
    &:nth-child(4) {
      width: 200px;
    }
    &:nth-child(5) {
      width: 150px;
    }
    &:nth-child(6) {
      width: 150px;
    }
    &:nth-child(6) {
      width: 200px;
    }
  }

  &__table {
    background-color: $white;
    border-bottom: 1px solid $gray-light-3;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0.8rem;
    @media (max-width: $tablet-xxl-breakpoint) {
      flex-direction: column;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
  }

  &__name {
    @include style-heading-small;
    text-transform: uppercase;
    font-size: 12px;
    padding-bottom: 0.35rem;
  }

  p {
    @include style-body-xsmall;
    color: $gray;
    line-height: 1.8;
    word-wrap: break-word;
    // word-break: break-word;
  }
}

@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../Dashboard/UploadSketches/AdditionalReference.scss';

.pattern {
  background-color: $neutral-white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  max-width: 62.6rem;

  @media (max-width: $tablet-breakpoint) {
    max-width: 100%;
  }

  .product-upload {
    padding: 16px 44px;
    &__drag-area {
      margin-top: 0;
      height: 455px;
    }
  }

  &__heading {
    @include style-title-medium;
    font-family: $optima;
    letter-spacing: 0;
    border-bottom: 1px solid $gray-light-3;
  }

  &__heading-wrapper {
    @extend .additional-reference__heading-wrapper;
  }

  &__heading-icons {
    @extend .additional-reference__heading-icons;
  }

  &__history {
    @extend .additional-reference__history;
  }

  &__viewmode {
    @extend .additional-reference__viewmode;
  }

  &__add {
    @extend .additional-reference__add;
  }

  &__body {
    padding: 1rem 1.5rem;
    min-height: 520px;

    & .inventory-table {
      max-height: 425px;
      overflow-x: hidden;
    }

    & .image-gallery-thumbnails-wrapper {
      & .image-gallery-thumbnails {
        overflow-y: unset;
        overflow-x: hidden;
        height: 455px !important;
        width: 100px;
        & .image-gallery-thumbnails-container {
          flex-direction: column;
          & .image-gallery-thumbnail {
            width: 100px;
            height: 100px;
            z-index: 0;
          }
        }
        -ms-overflow-style: none; /* IE and Edge - Hide scrollbar */
        scrollbar-width: none; /* Firefox - Hide scrollbar */
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    & .image-gallery-slide-wrapper {
      position: absolute;
      &.left {
        display: inline-block;
        width: calc(100% - 90px); // 110px total (90px + 20px for margin)
        padding: 0 40px 0 20px;
      }
      & .image-gallery-left-nav {
        padding-left: 30px;
      }
    }
  }

  &__thumbnail {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
  }

  &__thumbnail-img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    margin-right: 0;
  }

  &__actions {
    padding: 1rem 2rem;
  }

  &__tooltip {
    padding: 0rem 2rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__slide {
    position: relative;
    &.swiper-slide {
      background-position: center;
      background-size: cover;
      width: 770px;
      height: 495px;
    }
  }

  .thumbnail-header {
    position: absolute;
    background-color: transparent;
    height: 32px;
    width: 100%;
    right: 0.05rem;
    top: 0.05rem;
    z-index: 100;
    display: flex;
    justify-content: flex-end;

    img {
      filter: invert(50%);
    }
    .threedots {
      height: 32px;
      width: 32px;
      border-radius: 4px;
      margin: 8px 8px 0 0;

      & .pop-up__expand {
        min-width: unset;
      }
    }
  }
}

.patterns-list {
  .heading {
    @include style-body-small;
    color: $gray;
    text-transform: inherit;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $gray-lighter;
    padding: 0.8rem;

    @include style-body-small;
    color: $gray-dark;

    .pattern-metadata {
      font-family: $nunito-bold;
      color: $black-2;
      padding-bottom: 0.3rem;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;

      &__type {
        @include style-title-xsmall;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 2rem;
        margin: 0 1rem 0 0;
      }
      &__type:hover {
        overflow: visible;
        white-space: normal;
      }

      .pattern-icon {
        margin: 0 0.3rem;
      }
    }
    .pattern-content {
      @include style-body-small;
      text-transform: uppercase;
      color: $gray-medium;

      &__type {
        text-align: right;
        letter-spacing: 0.1em;
      }
    }
  }
}

@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.models-list {
  &__heading {
    @include style-title-medium;
    font-family: $optima;
    margin-bottom: 2.5rem;
    letter-spacing: 0;
    padding-top: 0.4rem;
  }

  .model-box {
    @include style-body-small;
    position: relative;
    background-color: $neutral-white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.8rem;

    &__image {
      min-height: 300px;
    }

    &__content {
      position: relative;
      @include style-body-small;
      background-color: $neutral-white;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      border-radius: 1px;
    }

    &__title {
      @include style-body-small;
      font-family: $nunito-medium;
      color: $black-2;
    }

    &__checkbox {
      position: absolute;
      left: 1rem;
    }

    &__dots {
      position: absolute;
      right: 1rem;
      cursor: pointer;
    }
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.upload-media {
  width: 97%;
  height: 95%;
  margin: 34px;
  left: -12px;
  overflow: hidden;
  bottom: -20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__block {
    position: relative;
    text-align: center;
  }

  &__txt {
    @include style-body-xsmall;
    font-family: $nunito-bold;
    letter-spacing: 0.1em;
    color: $black-2;
    text-transform: uppercase;
    padding-bottom: 0.3rem;
  }

  &__file {
    @include style-body-small;
    color: $black-2;
    padding-bottom: 1rem;
  }

  &--overlay {
    position: absolute;
    background: rgba(235, 244, 255, 0.5);
    border: 2px solid $color-blue;
    z-index: 9;
  }

  &--overlay.asset__loaded {
    background-color: transparent;
    border-color: transparent;
  }

  .pop-up__expand {
    background-color: $neutral-white;
    top: 5.9rem;
    right: 4rem;
  }
  &__upload-buttons > input {
    display: none;
  }
}

.frame .upload-atelier-assets {
  &__upload-buttons > input {
    display: none;
  }

  .pop-up {
    &__expand {
      top: 50%;
      left: 15%;
      width: 66%;
    }
  }
}

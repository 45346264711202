@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.ProductsLineView {
  .product-box {
    @include style-body-small;
    position: relative;
    background-color: $neutral-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #f9f9f9;

    &:first-child {
      border-top: 1px solid #f9f9f9;
    }

    &__checkbox {
      margin: 0 1rem;
    }

    &__body {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &__dots {
      cursor: pointer;
    }

    &__image {
      height: 40px;
    }

    &__content {
      margin-left: 1rem;
    }

    &__name {
      @include style-body-xsmall;
      font-family: $nunito-bold;
      text-transform: capitalize;
      letter-spacing: 0.1em;
    }

    &__id {
      @include style-body-xsmall;
      font-size: 10px;
      letter-spacing: 0.1em;
      text-transform: capitalize;
      color: $gray-medium;
    }
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.inventory-table {
  .table {
    background-color: $white;
  }

  .pop-up__expand {
    right: 3rem;
  }

  .control {
    @media (max-width: $tablet-breakpoint) {
      margin: 1.2rem 0;
    }
  }

  td.link {
    cursor: pointer;
  }
}

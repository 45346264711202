@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';


.create-temporary-password-modal {
  //== Modal updates
  .modal {
    .modal__inner {
      padding-top: 0;
    }
  }
}

.user-information {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  margin: 3px -2rem;
  padding: 0.3rem 1rem;

  span {
    @include style-body-small;
    font-family: $nunito-regular;
    text-transform: uppercase;
    color: $gray;
  }
}

.subtitle {
  @include style-body-small;
  font-family: $nunito-regular;
  color: $gray-medium;
}

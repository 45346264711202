.tooltip {
  position: absolute;
  width: 14.375rem;
  box-shadow: 0px 8px 16px rgba(153, 154, 154, 0.16);
  border-radius: 0.25rem;
  padding: 0.75rem;
  min-height: 6.25rem;
  background-color: $neutral-white;
  color: $gray;
  left: 2rem;
  top: -3rem;
  opacity: 0;
  transition: 350ms ease opacity;
  visibility: hidden;
  z-index: 10;

  &__txt {
    color: $gray;
    font-size: 0.875rem;
  }

  //* Black Variation *//
  &--black {
    background-color: $black-2;
    width: auto;
    height: auto;
    min-height: 100%;
    top: 2.35rem;
    left: -1.8rem;
    padding: 10px 12px;

    .tooltip__txt {
      @include style-body-small;
      color: $white;
      text-transform: capitalize;
      letter-spacing: 0;  
      font-size: 14px;
    }
  }
}

.select-basic {
  @include style-body-xsmall;
  color: $black-2;
  font-family: $nunito-bold;
  background-color: transparent;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

//Overriding styles
.react-dropdown-select {
  border: 1px solid $gray-light !important;
  border-radius: 1px !important;
  min-height: 40px !important;
  width: 352px !important;

  @media (max-width: $tablet-breakpoint) {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  &:active,
  &:focus,
  &:focus-within {
    outline: 0px !important;
    box-shadow: none !important;
  }
}

.react-dropdown-select-option {
  background-color: $button-gray !important;
  border: 1px solid $gray-light !important;
  border-radius: 2px !important;
  color: $gray-medium !important;
  font-size: 12px !important;
}

.react-dropdown-select-option-label {
  @include style-body-small;
  color: $gray-medium !important;
}

.react-dropdown-select-input {
  @include style-body-small;
  font-size: 16px !important;
  margin: 0 !important;
  &::placeholder {
    color: $light-black !important;
    padding: 0.2rem 0.3rem !important;
  }
}

.react-dropdown-select-dropdown-handle svg {
  width: 25px !important;
  height: 25px !important;
}

.react-dropdown-select-content span {
  @include style-body-small;
  font-family: $nunito-regular !important;
  font-size: 16px !important;
  padding: 0.2rem 0.2rem !important;
  position: absolute;
  text-transform: capitalize;
  color: $black-2;

  // Ellipsis for longer text
  text-overflow: ellipsis;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
}

.react-dropdown-select-item-selected {
  background-color: $gray-light-3 !important;
  color: $light-black !important;
}

.react-dropdown-select-item {
  @include style-body-small;
  font-size: 16px !important;
  padding: 0.8rem 1rem !important;
  color: $gray-medium;

  &:hover {
    background-color: $gray-lighter !important;
  }
}

.react-dropdown-select-dropdown {
  width: 352px !important;
  top: 38px !important;
  box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.12) !important;
  border-radius: 2px !important;
  border: 1px solid $gray-light !important;

  @media (max-width: $tablet-breakpoint) {
    width: 100% !important;
    min-width: 100% !important;
  }
}


//Default select
select {
  @include style-body-xsmall;
  border: 1px solid $gray-light;
  border-radius: 1px;
  height: 2.5rem;
  transition: 200ms ease all;
  cursor: pointer;
  min-width: 3.5rem;

  &:focus,
  &:visited,
  &:active {
    outline: none;
    border: 1px solid $gray;
    transition: 200ms ease all;;
  }
}

.helper-text {
  @include style-body-small;
  color: $gray-light-2;
  padding: 0.35rem 0;
}

.select-qty {
  @include style-body-xsmall;
  border: 1px solid $gray-light;
  border-radius: 1px;
  height: 2.5rem;
  transition: 200ms ease all;
  cursor: pointer;
  min-width: 3.5rem;
  padding: 0.5rem;
  background: rgba(0,0,0,0) url(../../assets/icons/caret-double.svg) no-repeat scroll calc(85% + 3px) center/8px auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus,
  &:visited,
  &:active {
    outline: none;
    border: 1px solid $gray;
    transition: 200ms ease all;;
  }
}
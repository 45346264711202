@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.side-navigation {
  // padding: 3rem 2rem;
  background-color: $frame-bg-gray;

  &__item {
    @include style-body-xsmall;
    font-family: $nunito-bold;
    text-transform: uppercase;
    color: $text-color;
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
    cursor: pointer;

    &.completed {
      .side-navigation__step {
        background-color: $black-2;
        &::before {
          content: url('../../../assets/icons/check.svg');
          @include flex-center;
          position: absolute;
          top: 2px;
          left: 0;
          right: 0;
        }
        &::after {
          background-color: $black-2;
        }
      }
    }

    &.not-started {
      color: $gray;

      .side-navigation__step {
        border-color: $gray;
      }
    }

    &:last-child {
      .side-navigation__step::after {
        height: 0;
        background: transparent;
      }
    }
  }

  &__step {
    position: relative;
    @include flex-center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 100%;
    border: 1px solid $black-2;
    margin-right: 1rem;

    &::after {
      height: 2rem;
      width: 1px;
      content: '';
      position: absolute;
      background: $gray-light;
      top: 19px;
    }
  }

  &__controls {
    padding-top: 2rem;
  }
}

.stepper-grid-template-columns {
  display: grid;
  grid-template-columns: minmax(150px, 23%) 1fr;
  padding: 3rem 0;

  @media (max-width: $tablet-breakpoint) {
    padding-top: 5rem;
    grid-template-columns: 1fr;
  }
}

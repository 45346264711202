@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.forgot-password {
  padding-top: 120px;
  max-width: 400px;
  margin: 0px auto;

  &__heading {
    @include style-title-medium;
    font-family: $optima;
    letter-spacing: 0;
  }

  &__subheading {
    @include style-body-small;
    margin: 0.5rem 0 2rem 0;
    color: $gray-medium;
  }

  .button-primary {
    width: 100%;
    margin: 1.5rem 0;
  }

  .link-gray {
    display: flex;
    width: fit-content;
    margin: 0px auto;
  }
}

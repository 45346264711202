@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.asset-library-file-upload {
  .modal__inner {
    padding: 5px 0;
  }

  .asset-files {
    .asset-file {
      height: 64px;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      background: #ffffff;
      border: 1px solid $gray-lighter;
      box-sizing: border-box;

      &__type {
        margin-right: 24px;
        padding: 10px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #cbcbcb;
        text-transform: uppercase;
        background: #f4f4f4;
        border-radius: 4px;
      }
      &__url {
        max-width: 70%;
        word-break: break-all;
      }
      &__delete {
        margin-left: auto;
      }
    }
  }
}

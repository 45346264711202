@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.product-upload {
  background-color: $neutral-white;
  border-radius: 0.5rem;
  padding: 0rem 2rem;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    @media (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }
  }

  &__heading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__heading-icons {
    @include style-body-xsmall;
    color: $gray;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    position: relative;

    .info-icon:hover {
      + .tooltip {
        opacity: 1;
        visibility: visible;
      }
    }

    .tooltip {
      top: 2rem;
      left: unset;
      right: 0;
      width: unset;
      height: unset;
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }
    &-preview {
      justify-content: flex-end;
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $tablet-breakpoint) {
      padding-top: 2rem;
    }

    .button-dashboard {
      a {
        display: flex;
      }
    }

    .btn-upload-img {
      margin-left: 0.8rem;
      margin-right: 0;
    }
  }

  &__heading {
    @include style-title-medium;
    font-family: $optima;
    letter-spacing: 0;
    @media (max-width: $tablet-breakpoint) {
      padding-bottom: 0.8rem;
    }
  }

  &__instructions {
    @include style-title-xsmall;
    font-size: 0.625rem;
    text-transform: uppercase;
  }

  &__subheading {
    @include style-body-xsmall;
    font-family: $nunito-bold;
    letter-spacing: 0.1em;
    color: $gray;
    padding-left: 1rem;
    text-transform: uppercase;
  }

  &__controls {
    padding-top: 2rem;
    padding: 1rem 1rem;
    display: flex;
  }

  &__drag-area,
  &__paste-area,
  &__info-area,
  &__preview-area {
    flex-direction: column;
    background: $frame-bg-gray;

    .image-upload {
      margin: 1rem;
    }
  }

  &__info {
    @include style-body-xsmall;
    color: $gray;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    position: relative;

    .tooltip {
      top: 2rem;
      left: 0;
    }

    &:hover {
      .tooltip {
        opacity: 1;
        visibility: visible;
      }
    }

    img {
      padding-right: 0.4rem;
    }
  }

  &__infosave {
    @include style-body-xsmall;
    color: $gray;
    display: flex;
    flex-direction: rows;
    margin-top: 0.5rem;
    cursor: pointer;
    position: relative;
    padding-left: 0.4rem;
    padding-bottom: 0.4rem;

    &:hover {
      .tooltip {
        opacity: 1;
        visibility: visible;
      }
    }

    img {
      margin-left: 0.4rem;
      margin-right: 9px;
      width: 14px;
      height: 14px;
    }
    span {
      font-size: 14px;
      color: #666665;
      font-weight: 600;
    }
  }

  .txt-gray {
    display: block;
    padding-bottom: 0.5rem;
  }

  .RadioButtons {
    padding: 0.313rem 0;
  }

  &__drag-area {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
    height: 19.5rem;
    border: 0.063rem dashed $gray-light;
  }

  &__empty-area {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    width: 100%;
    height: 19.5rem;
  }

  &__paste-area {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    width: 100%;
    height: 19.5rem;
    border: 0.063rem dashed $gray-light;
    textarea {
      height: 12.5rem;
      width: 100%;
    }
  }

  &__info-area {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 1.5rem;
    width: 100%;
    height: 40px;
    border: 1px solid $gray-light;
  }

  &__preview-area {
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 0.8rem;
    width: 100%;
  }

  &__txt {
    text-align: center;
    font-size: 0.688rem;
    padding: 0 2rem;
  }

  &__select {
    position: relative;
    display: flex;
    align-items: center;
  }

  .pop-up__expand {
    top: 3.8rem;
    right: 1rem;
  }
}

.image-gallery {
  width: 100%;
}

.BaseTable__header-cell {
  background: #f9f9f9;
}

@import '../../styles/utils';
@import '../../styles/variables';
@import '../../styles/mixins';

.product-listing-dashboard {
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2rem;
    font-size: 1rem;
    @media (max-width: $tablet-breakpoint) {
      padding-top: 2rem;
    }
  }

  &__arrows-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $tablet-breakpoint) {
      padding-bottom: 1rem;
    }
  }

  &__arrows {
    width: auto;
    display: flex;
    padding: 1rem 0;
  }

  &__tile {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__slide-title {
    @include style-subtitle;
  }

  &__download {
    display: flex;
    border-bottom: 1px solid $gray-light-3;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem 2.5rem;
  }

  &__light-box {
    width: 100%;
    position: fixed;
    height: 100%;
    background: $white;
    padding: 2rem;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 200;

    .product-listing-dashboard__main-img {
      width: 100%;
      height: auto;
      justify-content: center;
      position: relative;
      margin-top: 1rem;
      display: flex;
      margin: 0px auto;

      @media (max-width: $tablet-breakpoint) {
        width: 100%;
        margin-top: 2rem;
      }
    }

    .product-listing-dashboard__arrows {
      width: 1400px;
      position: initial;
      @media (max-width: $tablet-breakpoint) {
        width: 100%;
      }
    }
  }
  .product-listing-dashboard__arrows {
    display: flex;
  }

  &__lightbox-arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 70px 0 0;

    .product-listing-dashboard__main-prev {
      padding-right: 1rem;
    }
  }

  &__lightbox-buttons-wrap {
    display: flex;
  }

  &__lightbox-inner {
    position: relative;
    padding: 1rem 0;
  }

  &__lightbox-close {
    cursor: pointer;
    right: 2rem;
    position: absolute;
    top: 1rem;
    z-index: 9;
    @media (max-width: $tablet-breakpoint) {
    }
  }

  &__edit {
    img {
      transform: rotate(90deg);
    }
  }

  &__row {
    position: relative;
    border-bottom: 1px solid $gray-light-3;
  }

  &__accordion {
    cursor: pointer;
  }

  &__btn {
    display: flex;
    justify-content: flex-end;
  }

  &__arrow {
    cursor: pointer;
    position: absolute;
    transform: rotate(90deg);
    top: 10px;
    right: 0;
  }

  &__main {
    position: relative;
    height: 100%;
    @media (min-width: $desktop-breakpoint) {
      width: 516px;
      height: 688px;
    }
  }

  &__main-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: $tablet-breakpoint) {
      position: relative;
      width: 100%;
      height: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
      &:nth-child(2n) {
        display: none;
      }
    }
  }

  &__main-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;
    max-width: 516px;

    @media (max-width: $tablet-xl-breakpoint) {
      max-width: 100%;
    }

    .button-dashboard {
      letter-spacing: 0.1em;

      padding-left: 0;
      padding-right: 0;

      img {
        margin-right: 0.3rem;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  &__look-name {
    @include style-title-small;
    text-transform: uppercase;
    font-size: 0.625rem;
  }

  &__download {
    cursor: pointer;
  }

  &__title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    color: $gray;

    img {
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  &__btn {
    position: relative;
    z-index: 9;
    margin-top: 2rem;

    @media (max-width: $tablet-breakpoint) {
      margin: 2rem 0;
    }
  }

  .arrow-right {
    transform: rotate(0deg);
    transition: all ease 0.25s;
  }

  .arrow-bottom {
    transform: rotate(90deg);
    transition: all ease 0.25s;
  }

  &__main-prev {
    cursor: pointer;

    position: relative;
    transition: 300ms ease transform;
    padding-right: 3rem;

    &:active {
      transition: 300ms ease transform;
      transform: rotate(15deg);
    }
  }

  &__main-next {
    cursor: pointer;
    position: relative;
    transform: rotate(180deg);
    transition: 300ms ease transform;

    &:active {
      transition: 300ms ease transform;
      transform: rotate(200deg);
    }
  }

  &__thumbnail {
    height: 613px;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge - Hide scrollbar */
    scrollbar-width: none; /* Firefox - Hide scrollbar */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__right-rail {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    @media (max-width: $tablet-breakpoint) {
      display: none;
    }
  }

  &__thumbnail {
    @media (min-width: $tablet-breakpoint) {
      margin-right: 1rem;
      width: 80px;
    }
  }

  &__slider-col {
    display: flex;
  }

  &__slider-main {
    width: 80%;
    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }
  }

  &__slider {
    position: relative;
    overflow: hidden;
  }

  &__thumb-img {
    margin-bottom: 1rem;
    cursor: pointer;
    border: 0.063rem solid transparent;
    transition: 350ms ease border;
  }

  &__small-img {
    width: 5rem;
    height: 7.333rem;
    object-fit: cover;
    margin-bottom: 1rem;
    cursor: pointer;
    border: 0.063rem solid transparent;
    transition: 350ms ease border;
  }
  &__small-img-va {
    width: 4rem;
    height: 7.333rem;
    object-fit: cover;
    margin-bottom: 1rem;
    cursor: pointer;
    border: 0.063rem solid transparent;
    transition: 350ms ease border;
  }

  &__current-img {
    transition: 350ms ease border;
    border: 0.063rem solid $black-2;
  }

  &__edit {
    position: relative;
    cursor: pointer;

    .pop-up__expand {
      top: 1.5rem;
    }
  }

  &__categories {
    display: flex;
    flex-direction: row;
    padding-bottom: 1.5rem;
    font-size: 0.75rem;
  }

  &__cat-title {
    @include style-title-small;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $gray;
    width: 40%;
  }

  &__category {
    @include style-title-small;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $black-2;
  }

  &__count {
    @include style-title-small;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0rem auto;
    padding: 1rem 0;
  }

  &__lightbox-header {
    @include style-title-small;
    text-transform: uppercase;
    position: absolute;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 0.5rem 1rem;
    top: 0;
    align-items: center;
  }

  &__lightbox-inner {
    overflow-y: scroll;
  }

  &__content {
    padding-bottom: 1rem;
    color: $black-2;
  }

  &__flex-end {
    align-items: flex-end;
  }

  &__info {
    @media (max-width: $tablet-xl-breakpoint) {
      padding-top: 1rem;
    }

    // padding-left: 4rem;
    h3 {
      @include style-body-regular;
      font-family: $nunito-medium;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      padding: 0.5rem 0;
    }

    h5 {
      @include style-body-small;
      font-size: 0.75rem;
      font-family: $nunito-medium;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      padding: 1rem 0;
      margin: 0;
      display: flex;
      align-items: center;
    }

    h4 {
      @include style-body-regular;
      font-family: $nunito-regular;
      font-weight: 300;
      margin: 0;
    }
  }

  &__btns {
    margin-bottom: 2rem;
    button {
      margin-left: 1rem;
    }
  }

  &__tooltip {
    position: relative;
    display: inline-block;

    &:hover span {
      visibility: visible;
    }

    span {
      width: 4rem;
      overflow-wrap: break-word;
      visibility: hidden;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      top: 100%;
      @include style-body-xsmall;
      padding: 0.35rem;
      cursor: pointer;
      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black transparent;
      }
    }
  }

  .breadcrumbs {
    @media (max-width: $tablet-breakpoint) {
      padding-top: 1rem;
    }
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.dialog {
  position: fixed; /* Stay in place */
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(8, 7, 7, 0.4);
  box-shadow: $modal-box-shadow;

  &__content {
    @include center(both);
    box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.4);
    border-radius: 4px;
    color: $gray-medium;
    width: 40rem;

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
      overflow: scroll;
    }
  }

  &__footer {
    border-radius: 0px 0px 4px 4px;
    background-color: $neutral-white;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: flex-end;
    top: -1px;
    position: relative;
    button {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__inner {
    @include style-body-regular;
    background-color: $modal-bg-inner;
    padding: 1.5rem 2rem;
    border-radius: 4px 4px 0px 0px;
  }
}

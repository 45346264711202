@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.notifications {
  position: absolute;
  box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.12);
  border-radius: 4px;
  top: 1rem;
  right: 0;
  background-color: $neutral-white;
  z-index: 999;
  width: 320px;
  cursor: pointer;

  &__header {
    border-bottom: 1px solid $gray-light-3;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__headline {
    @include style-heading-small;
    text-transform: uppercase;
  }

  &__read {
    @include style-heading-small;
    text-transform: uppercase;
    color: $gray;
  }

  &__action {
    font-size: 12px;
    color: $gray-dark;
  }

  &__time {
    font-size: 10px;
    color: $gray;
    padding-top: 0.2rem;
  }

  &__content {
    width: 100%;
    padding: 0 1rem;
  }

  &__item {
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $gray-light-3;
  }

  &__img,
  &__thumbnail {
    background-color: $gray-light-2;
    border-radius: 100%;
    width: 2.2rem;
    height: 2.2rem;
    object-fit: cover;
  }

  &__thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
  }
}

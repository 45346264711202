@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.modal {
  position: fixed; /* Stay in place */
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(8, 7, 7, 0.4);
  box-shadow: $modal-box-shadow;
  border: none;
  overflow-y: initial;
  z-index: 99;

  p {
    @include style-body-regular;
  }

  &__footer {
    top: -1px;
    position: relative;
  }

  &__content {
    @include center(both);

    @media (max-width: $tablet-breakpoint) {
      width: 100%;
      overflow: scroll;
    }
  }

  &__inner-v2 {
    background-color: $modal-bg-inner;
  }

  &__footer {
    background-color: $neutral-white;
    padding: 1rem 1.5rem 2rem 1.5rem;
    display: flex;
    justify-content: flex-end;
    button {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .custom__footer {
      margin-right: auto;
    }
  }

  &__header {
    background-color: $neutral-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    position: relative;
    top: 1px;

    img {
      cursor: pointer;
    }
  }

  &__drag-txt {
    @include style-heading-small;
    text-transform: uppercase;
  }

  &__file-type {
    @include style-body-small;
    color: $gray-light-2;
    margin-bottom: 1rem;
  }

  &__inner {
    background-color: $modal-bg-inner;
    padding: 1.8rem 2rem;
    max-height: 65vh;
    overflow-x: hidden;
  }

  &__title {
    display: flex;
    flex-direction: column;
    @include style-title-medium;
    letter-spacing: 0;
    font-family: $optima;
  }

  &__subheading {
    @include style-body-xsmall;
    padding-top: 0.8rem;
    color: $gray;
  }

  &__drag-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  //Sizes
  &--regular {
    .modal__content {
      width: 46.5rem;
      min-height: 25rem;
      @media (max-width: $tablet-breakpoint) {
        width: 100%;
      }
    }
  }

  &--rounded {
    .modal__header {
      border-radius: 8px 8px 0px 0px;
    }
    .modal__footer {
      border-radius: 0px 0px 8px 8px;
    }
  }

  &--medium {
    .modal__content {
      @include center(both);
      width: 808px;
      min-height: 25rem;
      @media (max-width: $tablet-breakpoint) {
        width: 100%;
      }
    }
  }

  .button-primary {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid $black-2;
    }
  }
}

.profile-img {
  background-color: $gray-light-2;
  color: $white;
  border-radius: 100%;
  object-fit: cover;
  @include style-body-regular;
  font-family: $nunito-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
}

.logo-img {
  height: 40px;
  background-color: $gray-light-2;
  color: $white;
  object-fit: cover;
  @include style-body-regular;
  font-family: $nunito-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
}

.image-upload > input {
  display: none;
}

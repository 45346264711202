@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';


.colorway {
    background-color: $white;
    border: 0.063rem solid $gray-light-3;
    border-radius: 0.125rem;
    max-width: 20rem;
    padding: 1.5rem;

    @media (max-width: $mobile-breakpoint) {
        max-width: 100%;
    }

    &__heading {
        @include style-title-small;
        font-size: 1rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
    }

    &__row {
        @include style-body-small;
        display: flex;
        align-items: center;
        padding: 1.5rem;
        color: $gray-dark;
    }

    &__color-image {
        border-radius: 0.125rem;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }

    &__action {
        display: flex;
    }

    &--selected {
        border: 0.063rem solid $text-color;
    }
}
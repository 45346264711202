@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.sidebar-info {
  background-color: $white;
  border-left: 1px solid $gray-light;
  width: 18rem;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  padding: 1rem 1rem;
  z-index: 101;

  &__header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__header {
    margin-bottom: 1.5rem;
    border-bottom: 2px solid $gray-light;
    padding-bottom: 2rem;
  }

  &__heading {
    @include style-body-regular;
    padding-bottom: 2rem;
  }

  &__subheading {
    @include style-subtitle;
    color: $gray-light-2;
    padding-bottom: 0.5rem;
  }

  &__row {
    padding-bottom: 1.5rem;
  }

  &__text {
    @include style-body-small;
    color: $gray-medium;
    text-transform: capitalize;
  }

  &__type {
    display: flex;
    justify-content: center;

    img {
      height: 2.25rem;
      width: 2.25rem;
    }
  }

  &__members {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }

  &__member {
    @include style-body-small;
    background-color: $gray-light-2;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include style-subtitle;
    font-size: 14px;
    color: $white;
    margin-right: 0.5rem;
    height: 2.25rem;
    width: 2.25rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__profile-img {
    object-fit: cover;
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 100%;
  }
  &__profile-user {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 100%;
  }
  &__profile-add {
    height: 2.25rem;
    width: 1.25rem;
    border-radius: 100%;
    filter: invert(1);
  }
}

@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.login-screen {
  background: $login-bg-color;
  height: 100vh;
  padding: 2.2rem 0;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &__center {
    display: flex;
    justify-content: center;
  }

  &__right-aligned {
    display: flex;
    justify-content: flex-end;
  }

  &__mmlogo {
    max-width: 4rem;
  }

  &__footer {
    position: absolute;
    bottom: 3rem;
    left: 3rem;
  }

  &__powered-by-mm {
    @include style-body-xsmall;
    position: absolute;
    bottom: 3rem;
    right: 3rem;
    @media (max-width: $mobile-breakpoint) {
      right: 1;
    }
  }
}

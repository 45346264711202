.pop-up {
  &--large {
    width: 209px;
  }

  &__expand {
    position: absolute;
    top: 3rem;
    right: 0;
    box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.12);
    border-radius: 2px;
    z-index: 110;
    background-color: $neutral-white;
    min-width: 187px;
  }

  &__ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__li {
    @include style-body-small;
    list-style-type: none;
    color: $gray-medium;
    padding: 0.8rem 1rem;
    cursor: pointer;
    text-align: left;
    transition: 350ms ease all;
    text-transform: capitalize;

    &:hover {
      transition: 350ms ease all;
      background-color: $gray-light-3;
      color: $text-color;
    }
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.group-chat-modal {

  &__row {
    display: flex;
    @include style-body-small;
    @media (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }
  }

  &__left {
    width: 60%;
    padding: 1.5rem 0;
    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }
  }

  &__right {
    padding: 1.5rem 2rem;
    width: 40%;
    background: $modal-bg-inner;
    color: $gray;
    @media (max-width: $tablet-breakpoint) {
      width: 100%;
    }
  }

  &__group-name{
    padding: 0 2rem;
  }

  &__contacts {
    margin-top: 1rem;
    max-height: 11.5rem;
    overflow: scroll;
    padding: 0 2rem;
  }

  &__contact {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &__user {
    position: relative;
    width: 10%;
  }

  &__user-selected {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;    
    color: $neutral-black;
    .group-chat-modal__profile-pic {
      margin-right: 0.5rem;
    }
  }

  &__profile-pic {
    width: 2rem;
    height: 2rem;
    position: relative;
    background-size: cover;
    border-radius: 100%;
    margin: 0;
    padding: 0;
  }

  &__search {
    @include style-body-xsmall;
    color: $gray-dark;
    border-bottom: 0.063rem solid $border-color;
    padding: 0.35rem 0;
    margin: 0 2rem;
    margin-top: 1rem;
    cursor: pointer;

    input:focus,
    input:active,
    input::placeholder {
      outline: none;
      color: $gray-dark;
    }
  }

  // Overrides
  .modal__header {
    border-bottom: 0.063rem solid $gray-light;
  }

  .modal__inner {
    padding: 0;
  }

  .control--checkbox {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    letter-spacing: 0;
    text-transform: capitalize;
  }

  .Checkboxes {
    width: 100%;
  }

  .modal__footer {
    border-top: 0.063rem solid $gray-light;
    padding: 1rem 1.5rem 1rem 1.5rem;
  }

  .selectionMessage {
    padding: .5rem;
    background: #fff5f5;
    color: #C53030;
    border: 1px solid #C53030;
    border-radius: .125rem;
    display: flex;
    align-items: center;
    span {
      margin-right: .5rem;
    }
  }

  .errorBox {
    .text {
      font-size: 12px;
    }
  }

  .errorAlert {
    display: flex;
    align-items: center;
    color: #C53030;
    font-size: 12px;
    font-weight: 600;
  }

  .material-icons {
    &.em {
      font-size: 14px;
      margin-right: .25rem;
    }
  }

  .control {
    &.invalid {
      background: #fff5f5;
      color: #C53030;
      border: 1px solid #C53030;
    }
  }

}

.table {
  min-width: 100%;
  border: 1px solid $gray-light-3;

  tr {
    border-top: 1px solid $gray-light-3;
    border-bottom: 1px solid $gray-light-3;
  }

  th {
    @include style-title-small;
    font-size: 12px;
    display: none; // for accessibility
    color: $gray;
    text-transform: uppercase;
    padding: 0.5rem 0;
  }

  .drag-reorder-tr {
    &:hover {
      .drag-reorder-wrapper {
        .drag-reorder-icon {
          visibility: visible;
          cursor: pointer;
        }
      }
    }
    .drag-reorder-wrapper {
      display: flex;
      .drag-reorder-icon {
        margin-right: 14px;
        visibility: hidden;
      }
    }
  }

  .table-img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    border-radius: 4px;

    &.icon-folder {
      cursor: pointer;
    }
  }

  thead {
    @media (max-width: $tablet-breakpoint) {
      display: none;
    }
  }

  td {
    @include style-body-xsmall;
    display: block;
    color: $light-black;
    position: relative;

    &:before {
      content: attr(data-th) ''; // who knew you could do this? The internet, that's who.
      font-weight: bold;
      letter-spacing: 1px;
      width: 8rem; // magic number :( adjust according to your own content
      display: inline-block;
      // end options

      @media (max-width: $tablet-breakpoint) {
        padding: 0.6rem 0.5rem 0.6rem 1rem;
        text-transform: uppercase;
        color: $gray;
        font-size: 0.725rem;
      }

      @media (min-width: $tablet-breakpoint) {
        display: none;
      }
    }
  }

  th,
  td {
    text-align: left;
    display: flex;
    align-items: center;

    @media (min-width: $tablet-breakpoint) {
      display: table-cell;

      &:first-child {
        padding-left: 1rem;
        display: flex;

        .checkbox-label {
          margin-right: 0.6rem;
          top: 3px;
        }
      }

      &:last-child {
        padding-right: 1rem;
      }
    }
  }

  th {
    @media (min-width: $tablet-breakpoint) {
      padding: 0.5rem 0;
    }
  }

  td {
    @media (min-width: $tablet-breakpoint) {
      padding: 1rem 0.3rem;
    }

    @media (max-width: $tablet-breakpoint) {
      display: flex;
      align-items: center;
    }
  }

  // Regular Font
  &--fs-regular {
    td {
      @include style-body-small;
    }
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.TeamView {
  padding: 0 0 3rem 0;
}

.manage-team {
  background-color: $neutral-white;
  padding: 1.4rem 2rem;

  &__header {
    padding: 1.4rem 2rem;
    border-radius: 0.25rem 0.25rem 0px 0px;
  }

  &__headline {
    @include style-title-medium;
    letter-spacing: 0;
    font-family: $optima;
    button {
      padding: 0 1rem;
    }
  }

  td {
    position: relative;
    padding: 0.7rem 0.3rem;

    &:last-child {
      position: relative;
      display: flex;
      justify-content: flex-end;
    }
  }

  .button-bandge {
    width: fit-content;
  }

  .control--checkbox {
    margin-right: 0.4rem;
    padding-left: 2rem;
  }

  &__dots {
    cursor: pointer;
  }

  &__expand {
    position: absolute;
    width: 160px;
    top: 3rem;
    right: 0;
    box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.12);
    border-radius: 2px;
    z-index: 9;
    background-color: $neutral-white;
  }

  &__ul {
    list-style-type: none;
  }

  &__li {
    list-style-type: none;
    color: $gray-medium;
    padding: 0.8rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: $gray-light-3;
    }
  }

  .collections-list__item {
    padding-left: 2rem;
  }
}

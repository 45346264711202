/* Utility Classes */

.uppercase {
  text-transform: uppercase;
}

.unset {
  text-transform: unset;
}

.cursor-pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.font-bold {
  font-weight: 600;
}

.spacer-sm {
  height: 1.5rem;
  position: relative;
}

.container-large {
  height: 100%;
  margin: 0rem auto;
  padding: 0 0.6rem;

  @media (min-width: $desktop-breakpoint) {
    max-width: 1440px !important; //global
  }
}

.container {
  height: 100%;
  margin: 0rem auto;

  @media (max-width: $tablet-breakpoint) {
    padding: 0 0.6rem;
  }

  @media (min-width: $desktop-breakpoint) {
    max-width: 1300px !important; //global
  }
}

.hide {
  display: none;
}

.row-reverse-mobile {
  @media (max-width: $tablet-breakpoint) {
    flex-direction: column-reverse;
  }
}

//Accordion
.hidden-accordion {
  transition: max-height ease-out 500ms, opacity ease-out 200ms;
  opacity: 0;
  max-height: 0px;
}

.visible-accordion {
  transition: max-height ease-in 400ms, opacity ease-in 600ms;
  opacity: 1;
  max-height: 400px;
}

//Pop up
.hidden-popup {
  opacity: 0;
  transition: opacity ease 0.25s;
  visibility: hidden;
}

.visible-popup {
  transition: opacity ease 0.25s;
  opacity: 1;
  visibility: visible;
  z-index: 99;
}

//Colors
.color-white {
  color: $neutral-white;
}

.color-black {
  color: $neutral-black;
}

// Pre-book
.color-pre-book-bg {
  color: #4b4b4b;
  background-color: #ebf4ff;
}

// Immediate
.color-immediate-bg {
  color: #4b4b4b;
  background-color: #f0fff4;
}

// Aspect Ratios

.ratio1-1 {
  padding-top: 100%;
}

.ratio2-3 {
  padding-top: 150%;
}

.ratio1-2 {
  padding-top: 200%;
}

.ratio3-4 {
  padding-top: 133.33%;
}

.ratio16-9 {
  padding-top: 56.25%;
}

.ratio9-16 {
  padding-top: 177.77%;
}

.ratio16-7 {
  padding-top: 43.75%;
}

.ratio-wrap {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.ratio-img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  &__fill {
    object-fit: fill;
  }
  &__contain {
    object-fit: contain;
  }
  &__scale-down {
    object-fit: scale-down;
  }
}

.ratio-flex-center {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ratio-height-flex-center {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

//Color Utils
.color-black-bg {
  background-color: $neutral-black;
  color: $neutral-white;
}

.color-gray-bg {
  background-color: $button-gray;
  color: $gray-medium;
}

.color-onboarded-bg {
  background-color: #c6f6d5;
  color: #4b4b4b;
}

.color-active-bg {
  background-color: $button-active-bg;
  color: $gray-dark;
}

.color-inactive-bg {
  background-color: $button-inactive-bg;
  color: $gray-dark;
}

.color-warning-bg {
  background-color: $warning-color;
  color: $neutral-white;
}

.color-info-bg {
  background-color: $info-color;
  color: $neutral-white;
}

.color-success-bg {
  background-color: $success-color;
  color: $neutral-white;
}

.color-error-bg {
  background-color: $error-color;
  color: $neutral-white;
}

.color-gray-bg {
  background-color: $button-gray;
  color: $gray-medium;
}

.color-approved-bg {
  background-color: $approved;
  color: $text-color;
}

.color-todo-bg {
  background-color: $todo;
  color: $text-color;
}

.color-uploaded-bg {
  background-color: $gray-light-3;
  color: $text-color;
}

.color-white-bg {
  background-color: $neutral-white;
  color: $gray-medium;
}

.color-white-bd-bg {
  background-color: $neutral-white;
  color: $gray;
  border: 1px solid $gray-light;
}

.color-gray-bd-bg {
  background-color: $button-gray;
  color: $gray-medium;
  border: 1px solid $gray-light;
}

.color-gray-black {
  background-color: $button-gray;
  color: $black-2;
  border: 1px solid $button-gray;
  &:hover {
    border: 1px solid $black-2;
  }
}

.color-gray {
  color: $gray;
}

.color-white {
  color: $neutral-white;
}

.color-error-3-bg {
  background-color: $error-3;
  color: $text-color;
}

.color-info-3-bg {
  background-color: $info-3;
  color: $text-color;
}

.color-warning-3-bg {
  background-color: $warning-3;
  color: $text-color;
}

.color-declined-bg {
  background-color: $declined;
  color: $white !important;
}

.color-success-3-bg {
  background-color: $success-3;
  color: $text-color;
}

.color-success-2-bg {
  background-color: $success-2;
  color: $white !important;
}

.nunito-bold {
  font-family: $nunito-bold;
}

.flex-inline {
  display: flex;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-block {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-text {
  @include style-body-small;
  font-family: $nunito-bold;
  font-size: 0.875rem;
  color: $color-red;
  padding-left: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 1rem;
    height: 1rem;
    top: 2px;
    background-image: url('../../assets/icons/check-circle-full-error.svg');
    background-repeat: no-repeat;
  }
}

.error-empty-text {
  @include style-body-small;
  font-family: $nunito-bold;
  font-size: 0.875rem;
  color: $gray-dark;
  padding-left: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 1rem;
    height: 1rem;
    top: 2px;
    background-image: url('../../assets/icons/info-circle-full.svg');
    background-repeat: no-repeat;
  }
}

.line {
  width: 100%;
  border-bottom: 1px solid $black-2;
  padding: 0.5rem 0;
}

.letter-spacing {
  letter-spacing: 0.1em;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

//Slick
.slick-prev:before {
  content: url('../../assets/icons/angle-left-gray.svg') !important;
  filter: invert(50%);
}

.slick-next:before {
  content: url('../../assets/icons/Icon-angle-right-gray.svg') !important;
  filter: invert(50%);
}

.slick-next {
  right: 1rem;
}

.slick-prev {
  left: 1rem;
  z-index: 9;
}

//== Arrow Imgs
.arrow-right {
  transform: rotate(0deg);
  transition: all ease 0.25s;
}

.arrow-bottom {
  transform: rotate(90deg);
  transition: all ease 0.25s;
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.alert {
  padding: 1.2rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &__content {
    @include style-body-xsmall;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__alert-icon {
    margin-right: 0.5rem;
  }

  &__title {
    font-family: $nunito-bold;
    text-transform: capitalize;
    margin-right: 0.3rem;
  }

  &__close {
    position: absolute;
    right: 1rem;
    cursor: pointer;
  }

  //Modifiers
  &--error {
    color: $error-color;
    background-color: $alert-bg-error;
    border-bottom: 1px solid $error-color;

    .alert__close {
      path {
        fill: $error-color;
      }
    }
  }

  &--info {
    color: $gray-medium;
    background-color: $alert-bg-info;
    border: 1px solid $gray-light;

    .alert__close {
      path {
        fill: $gray-medium;
      }
    }
  }

  &--warning {
    color: $warning-color-2;
    background-color: $alert-bg-warning;
    border-bottom: 1px solid $warning-color-2;

    .alert__close {
      path {
        fill: $warning-color-2;
      }
    }
  }

  &--success {
    color: $success-color;
    background-color: $alert-bg-success;
    border-bottom: 1px solid $success-color;

    .alert__close {
      path {
        fill: $success-color;
      }
    }
  }

  &-enter {
    opacity: 0;
    transform: scale(0.9);

    &-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms, transform 300ms;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 300ms, transform 300ms;
    }
  }
}

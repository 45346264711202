@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.TeamView {
  .collections-list {
    padding: 2rem 0;
  }
}
.collections-list {
  &__heading {
    @include style-title-medium;
    font-family: $optima;
    margin-bottom: 2.5rem;
    letter-spacing: 0;
    padding-top: 0.4rem;
  }

  &__heading-item {
    position: relative;
    background-color: $neutral-white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.8rem 0;
    cursor: pointer;
  }

  &__item {
    position: relative;
    background-color: $neutral-white;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    margin-bottom: 0.8rem;
    padding-left: 5rem;
    cursor: pointer;
    justify-content: space-between;
  }

  &__txt {
    @include style-subtitle;
    text-align: left;
  }

  &__members {
    display: flex;
    align-content: flex-end;
    &-count {
      @include txt-gray;
      @include style-body-xsmall;
      color: $gray;
      padding: 0 0 0 0.6rem;
    }
  }

  &__end {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__icon {
    margin-right: 1.8rem;
  }

  &__checkbox {
    position: absolute;
    left: 1rem;
  }
}

@import '../../styles/utils';
@import '../../styles/variables';
@import '../../styles/mixins';

.masonry-layout {
  display: flex;

  @media (max-width: $tablet-breakpoint) {
    flex-direction: column;
  }

  &__item {
    @media (max-width: $tablet-breakpoint) {
      margin-left: 0 !important;
    }
  }
}

@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.asset-library-folder {
  position: relative;
  padding: 0.25rem 0;

  &__heading {
    @include style-subtitle;
    font-size: 0.625rem;
    padding-bottom: 0.5rem;
    color: $black-2;
    cursor: pointer;
  }

  &__folder {
    margin-right: 0.5rem;
  }

  &__arrow {
    margin-right: 0.5rem;
    width: 0.6rem;
    height: 0.6rem;
  }

  &__heading-has-children {
    cursor: pointer;
  }
}

.asset-library-subfolder {
  position: relative;
  padding: 0.25rem 0;
  padding-left: 0.8rem;

  &__heading {
    @include style-body-small;
    font-size: 0.75rem;
    color: $gray;
    text-transform: none;
    letter-spacing: 0;
    // padding-left: 2rem;
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    cursor: pointer;

    &.active {
      color: $black-2;
    }
  }
  &__heading-has-children {
    cursor: pointer;
  }
}

// Typography Classes


.style-subtitle {
  @include style-subtitle;
}

.style-title-small {
  @include style-title-small;
}

.style-title-xsmall {
  @include style-title-xsmall;
}

.style-body-xsmall {
  @include style-body-xsmall;
}

.font-smoothing {
  @include font-smoothing;
}

.txt-gray {
  @include txt-gray;
  color: $gray;
}

.txt-gray-light {
  @include txt-gray;
  color: $gray-light-2;
}

.link-gray {
  @include style-title-small;
  font-weight: bold;
  border-bottom: 1px solid $gray;
  color: $gray;
  text-transform: uppercase;
}

.optima {
  @include optima-font();
}

.style-heading-small {
  @include style-heading-small;
}

.paratype {
  @include paratype-font();
}


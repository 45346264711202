@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.product-media {
  &__p {
    @include style-body-xsmall;
    color: $gray-medium;
    padding-top: 0.4rem;
    padding-bottom: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__block-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__img-block {
    padding-right: 0.5rem;
    width: 25%;
  }

  &__txt {
    @include style-body-small;
    color: $gray;
    padding-bottom: 1.5rem;
  }

  &__p:hover {
    overflow: visible;
    white-space: normal;
    height: auto; /* just added this line */
  }
  &__title {
    @include style-heading-small;
    font-size: 12px;
    text-transform: uppercase;
    color: $black-2;
    padding-top: 1.2rem;
    padding-bottom: 0.5rem;
  }

  &__img {
    cursor: grab;
  }

  &__dots {
    float: right;
    cursor: pointer;
  }

  &__dots-container {
    height: 1rem;
  }

  .pop-up__expand {
    right: -1rem !important;
    width: max-content;
    min-width: 115px;
    top: 1.2rem !important;
  }
}

.product-listing-dashboard__main {
  .product-media {
    &__dots {
      position: absolute;
      bottom: 1em;
      right: 1em;
      z-index: 10;
    }
  }
}

@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.product-detail-modal {
  p {
    @include style-body-regular;
  }

  // Modifing Modal.scss inside the parent
  .modal__title {
    @include style-title-small;
    color: $gray;
    text-transform: uppercase;
    font-size: 10px;
  }

  .modal__inner {
    padding-bottom: 0;
  }

  .modal__footer {
    top: -1px;
    position: relative;
    justify-content: center;
  }
}


//== Product Variation
.product {
  &__title {
    @include style-title-small;
    text-transform: uppercase;
    font-size: 14px;
    color: $text-color;
    margin-bottom: 3rem;
  }
  
  label {
    @include style-title-small;
    color: $gray;
    text-transform: uppercase;
    font-size: 12px;
  }
  
  &__property {
    @include style-title-small;
    text-transform: uppercase;
    font-size: 14px;
    color: $gray-dark;
    margin-bottom: 2rem;

    button {
      display: block;
    }
  }
}

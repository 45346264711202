@import '../../../styles/utils';
@import '../../../styles/variables';
@import '../../../styles/mixins';

.sidebar {
  // max-width: 300px;
  width: 30%;
  border-top: 1px solid $gray-light-3;
  border-right: 2px solid $gray-light-3;
  height: 100%;
  padding: 1.5rem;
  background: #ffffff;

  &__heading {
    font-weight: bold;
    padding-bottom: 3rem;
  }

  &__item-heading {
    @include style-heading-small;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    font-style: bold;
  }

  &__item {
    border-top: 1px solid $sidebar-border-color;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__item-name {
    @include style-heading-small;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
  }

  &__cp-header {
    margin-bottom: 1rem;
  }

  &__color-picker {
    background: $neutral-white;
    box-shadow: 0px 4px 16px rgba(8, 7, 7, 0.1);
    position: absolute;
    bottom: 0;
    z-index: 9;
    width: 272px;
    padding: 0 20px;
  }

  &__cp-box {
    border-radius: 2px;
    width: 24px;
    height: 24px;
    display: flex;
    margin-right: 10px;
    margin-left: 10px;
  }

  &__cp-hex-input {
    @include style-body-small;
    font-size: 14px;
    background: $button-gray;
    height: 30px;
    align-items: center;
    color: $gray-medium;
    outline: none;
  }
  &__cp-rgb-input {
    @extend .sidebar__cp-hex-input;
    width: 30px;
    height: 30px;
  }
  &__cp-rgb-label {
    padding-right: 10px;
    padding-left: 10px;
  }

  &__cp-color {
    @include style-body-small;
    font-size: 14px;
    display: flex;
    margin: 0px auto;
    background: $button-gray;
    border: 1px solid $gray-light;
    box-sizing: border-box;
    border-radius: 1px;
    height: 32px;
    align-items: center;
    color: $gray-medium;
  }

  &__cp-color-rgb {
    @extend .sidebar__cp-color;
    width: 64px;
    height: 32px;
    margin-left: 15px;
  }

  &__cp-hue-slider {
    width: 224px;
    height: 8px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__cp-rgb-input-container {
    display: flex;
    flex-direction: column;
    height: 128px;
    justify-content: space-between;
  }

  &__cp-saturation-container {
    width: 144px;
    height: 128px;
    position: relative;
  }

  &__cp-center-content-container {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
  }

  &__cp-close {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 0;
    img {
      max-width: 1rem;
      margin: 0.5rem 0;
      cursor: pointer;
    }
  }

  &__color {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid $gray-light-2;
    cursor: pointer;
  }

  &__tooltip-svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    cursor: pointer;
    transition: 350ms ease fill;
    fill: $gray-light;
    &:hover {
      path {
        fill: $neutral-black;
        transition: 350ms ease fill;
      }
    }
  }

  &__info {
    position: relative;
  }

  &__tooltip {
    position: absolute;
    width: 230px;
    box-shadow: 0px 8px 16px rgba(153, 154, 154, 0.16);
    border-radius: 4px;
    padding: 0.8rem;
    background-color: $neutral-white;
    color: $gray;
    left: 2rem;
    top: -65px;
    opacity: 0;
    transition: 350ms ease opacity;
    visibility: hidden;
  }

  &__info {
    &:hover {
      .sidebar__tooltip {
        opacity: 1;
        z-index: 9;
        visibility: visible;
        transition: 350ms ease opacity;
      }
    }
  }

  &__title {
    @include style-body-small;
    color: $gray;
    display: flex;
    flex-direction: row;
  }
  &__wrap {
    position: static;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $gray;
  }

  &__wrap-close {
    @extend .sidebar__wrap;
    justify-content: left;
  }

  &__arrow {
    cursor: pointer;
  }
}

@import '../../styles/utils';
@import '../../styles/variables';
@import '../../styles/mixins';

.toast-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

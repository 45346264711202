@import '../../../../styles/utils';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.asset-library-page {
  padding-top: 0.5rem;
  min-height: 100vh;

  &__heading {
    padding-top: 1rem;
    @include style-subtitle;
    color: $black-2;
    max-width: 12.5rem;
    padding-bottom: 0.25rem;
    margin-bottom: 1rem;
    @media (max-width: $tablet-breakpoint) {
      max-width: 100%;
    }
    cursor: pointer;
  }

  &__flex {
    display: flex;
    flex-wrap: wrap;
  }

  &__flex-item {
    position: relative;
    flex: 0 0 18rem;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    @media (max-width: $mobile-breakpoint) {
      flex: none;
      width: 100%;
    }
  }

  &__empty {
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: $white;
    justify-content: center;
    margin-bottom: 2rem;

    @media (max-width: $mobile-breakpoint) {
      min-height: 100%;
      padding: 4rem 0;
    }
  }

  &__empty-h {
    @include style-subtitle;
    color: $black-2;
    padding-bottom: 0.25rem;
  }

  &__empty-p {
    @include style-body-small;
    color: $gray;
  }
}

@import './colors';

$container-main: 75.063rem;
$container-large: 76.25rem;

// FONTS

//nunito
$nunito-bold: 'NunitoSans-Bold', sans-serif;
$nunito-light: 'NunitoSans-Light', sans-serif;
$nunito-medium: 'NunitoSans-SemiBold', sans-serif;
$nunito-regular: 'NunitoSans-Regular', sans-serif;

//Optima

$optima: 'Optima-Medium', sans-serif;
$optima-italic: 'Optima-Italic', sans-serif;

//paratype
$paratype: 'ParaTypeSans', sans-serif;

// Breakpoints
$mobile-breakpoint: 26rem;
$tablet-breakpoint: 48.5rem;
$tablet-xl-breakpoint: 62rem;
$tablet-xxl-breakpoint: 64rem;
$desktop-breakpoint: 75rem;


// Frame
$frame-top-header: 4.6rem;
